import "./Welcome.scss";
import Ipp23 from "../components/ippupdate23/ipp23";

function Welcome() {
  var svgbgcolor = "#0DBBFC";

  return (
    <div className="Welcome">
      <div className="welcome-wrapper">
        <h1>Welcome</h1>
        <Ipp23 />
        <div className="intro">
          We at Information Science and Technology Association, are a
          staff-student run body striving to work hard for the betterment of The
          Department of Information Science and Technology, College of
          Engineering Guindy.
        </div>

        <div className="social-div">
          <div className="social-handles">
            <a href="https://www.instagram.com/ista__ceg/" target="_blank">
              <img src="../Instagram-icon.svg" alt="" />
            </a>
            <a href="mailto:ista@auist.net" target="_blank">
              <img src="../email-icon.svg" class="email-logo" alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/ista-ceg/mycompany/"
              target="_blank"
            >
              <img src="../linkedin-icon.svg" alt="" />
            </a>
          </div>
        </div>

        <button className="explore">Explore</button>
      </div>

      <div className="bg">
        <div className="bg-end" />

        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <pattern
            id="pattern-checkers"
            x="0"
            y="0"
            width="603"
            height="588"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M38.3854 8.36466C40.6912 8.36466 42.5605 6.49538 42.5605 4.1895C42.5605 1.88363 40.6912 0.0143433 38.3854 0.0143433C36.0795 0.0143433 34.2102 1.88363 34.2102 4.1895C34.2102 6.49538 36.0795 8.36466 38.3854 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 8.36466C575.226 8.36466 577.096 6.49538 577.096 4.1895C577.096 1.88363 575.226 0.0143433 572.921 0.0143433C570.615 0.0143433 568.745 1.88363 568.745 4.1895C568.745 6.49538 570.615 8.36466 572.921 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 8.3784C307.967 8.3784 309.843 6.50285 309.843 4.18922C309.843 1.87558 307.967 -1.52588e-05 305.654 -1.52588e-05C303.34 -1.52588e-05 301.464 1.87558 301.464 4.18922C301.464 6.50285 303.34 8.3784 305.654 8.3784Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 8.36466C174.326 8.36466 176.196 6.49538 176.196 4.1895C176.196 1.88363 174.326 0.0143433 172.02 0.0143433C169.715 0.0143433 167.845 1.88363 167.845 4.1895C167.845 6.49538 169.715 8.36466 172.02 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 8.36466C241.142 8.36466 243.011 6.49538 243.011 4.1895C243.011 1.88363 241.142 0.0143433 238.836 0.0143433C236.53 0.0143433 234.661 1.88363 234.661 4.1895C234.661 6.49538 236.53 8.36466 238.836 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 8.36466C107.509 8.36466 109.378 6.49538 109.378 4.1895C109.378 1.88363 107.509 0.0143433 105.203 0.0143433C102.897 0.0143433 101.028 1.88363 101.028 4.1895C101.028 6.49538 102.897 8.36466 105.203 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 8.36466C508.409 8.36466 510.278 6.49538 510.278 4.1895C510.278 1.88363 508.409 0.0143433 506.103 0.0143433C503.797 0.0143433 501.928 1.88363 501.928 4.1895C501.928 6.49538 503.797 8.36466 506.103 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 8.3784C374.785 8.3784 376.66 6.50285 376.66 4.18922C376.66 1.87558 374.785 -1.52588e-05 372.471 -1.52588e-05C370.158 -1.52588e-05 368.282 1.87558 368.282 4.18922C368.282 6.50285 370.158 8.3784 372.471 8.3784Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 8.36466C441.591 8.36466 443.461 6.49538 443.461 4.1895C443.461 1.88363 441.591 0.0143433 439.285 0.0143433C436.98 0.0143433 435.11 1.88363 435.11 4.1895C435.11 6.49538 436.98 8.36466 439.285 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 8.36466C7.29231 8.36466 9.16159 6.49538 9.16159 4.1895C9.16159 1.88363 7.29231 0.0143433 4.98644 0.0143433C2.68056 0.0143433 0.811279 1.88363 0.811279 4.1895C0.811279 6.49538 2.68056 8.36466 4.98644 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 8.36466C541.811 8.36466 543.681 6.49538 543.681 4.1895C543.681 1.88363 541.811 0.0143433 539.505 0.0143433C537.2 0.0143433 535.33 1.88363 535.33 4.1895C535.33 6.49538 537.2 8.36466 539.505 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 8.36466C274.543 8.36466 276.412 6.49538 276.412 4.1895C276.412 1.88363 274.543 0.0143433 272.237 0.0143433C269.931 0.0143433 268.062 1.88363 268.062 4.1895C268.062 6.49538 269.931 8.36466 272.237 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 8.36466C140.913 8.36466 142.782 6.49538 142.782 4.1895C142.782 1.88363 140.913 0.0143433 138.607 0.0143433C136.301 0.0143433 134.432 1.88363 134.432 4.1895C134.432 6.49538 136.301 8.36466 138.607 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 8.36466C207.725 8.36466 209.595 6.49538 209.595 4.1895C209.595 1.88363 207.725 0.0143433 205.419 0.0143433C203.114 0.0143433 201.244 1.88363 201.244 4.1895C201.244 6.49538 203.114 8.36466 205.419 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 8.36466C74.0955 8.36466 75.9648 6.49538 75.9648 4.1895C75.9648 1.88363 74.0955 0.0143433 71.7896 0.0143433C69.4838 0.0143433 67.6145 1.88363 67.6145 4.1895C67.6145 6.49538 69.4838 8.36466 71.7896 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 8.36466C474.996 8.36466 476.865 6.49538 476.865 4.1895C476.865 1.88363 474.996 0.0143433 472.69 0.0143433C470.384 0.0143433 468.515 1.88363 468.515 4.1895C468.515 6.49538 470.384 8.36466 472.69 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 8.36466C341.36 8.36466 343.23 6.49538 343.23 4.1895C343.23 1.88363 341.36 0.0143433 339.055 0.0143433C336.749 0.0143433 334.879 1.88363 334.879 4.1895C334.879 6.49538 336.749 8.36466 339.055 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 8.36466C408.178 8.36466 410.047 6.49538 410.047 4.1895C410.047 1.88363 408.178 0.0143433 405.872 0.0143433C403.566 0.0143433 401.697 1.88363 401.697 4.1895C401.697 6.49538 403.566 8.36466 405.872 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 44.9155C40.6912 44.9155 42.5605 43.0463 42.5605 40.7404C42.5605 38.4345 40.6912 36.5652 38.3854 36.5652C36.0795 36.5652 34.2102 38.4345 34.2102 40.7404C34.2102 43.0463 36.0795 44.9155 38.3854 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 44.9155C575.226 44.9155 577.096 43.0463 577.096 40.7404C577.096 38.4345 575.226 36.5652 572.921 36.5652C570.615 36.5652 568.745 38.4345 568.745 40.7404C568.745 43.0463 570.615 44.9155 572.921 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 44.9275C307.967 44.9275 309.843 43.0519 309.843 40.7383C309.843 38.4247 307.967 36.5491 305.654 36.5491C303.34 36.5491 301.464 38.4247 301.464 40.7383C301.464 43.0519 303.34 44.9275 305.654 44.9275Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 44.9155C174.326 44.9155 176.196 43.0463 176.196 40.7404C176.196 38.4345 174.326 36.5652 172.02 36.5652C169.715 36.5652 167.845 38.4345 167.845 40.7404C167.845 43.0463 169.715 44.9155 172.02 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 44.9155C241.142 44.9155 243.011 43.0463 243.011 40.7404C243.011 38.4345 241.142 36.5652 238.836 36.5652C236.53 36.5652 234.661 38.4345 234.661 40.7404C234.661 43.0463 236.53 44.9155 238.836 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 44.9155C107.509 44.9155 109.378 43.0463 109.378 40.7404C109.378 38.4345 107.509 36.5652 105.203 36.5652C102.897 36.5652 101.028 38.4345 101.028 40.7404C101.028 43.0463 102.897 44.9155 105.203 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 44.9155C508.409 44.9155 510.278 43.0463 510.278 40.7404C510.278 38.4345 508.409 36.5652 506.103 36.5652C503.797 36.5652 501.928 38.4345 501.928 40.7404C501.928 43.0463 503.797 44.9155 506.103 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 44.9275C374.785 44.9275 376.66 43.0519 376.66 40.7383C376.66 38.4247 374.785 36.5491 372.471 36.5491C370.158 36.5491 368.282 38.4247 368.282 40.7383C368.282 43.0519 370.158 44.9275 372.471 44.9275Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 44.9155C441.591 44.9155 443.461 43.0463 443.461 40.7404C443.461 38.4345 441.591 36.5652 439.285 36.5652C436.98 36.5652 435.11 38.4345 435.11 40.7404C435.11 43.0463 436.98 44.9155 439.285 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 44.9155C7.29231 44.9155 9.16159 43.0463 9.16159 40.7404C9.16159 38.4345 7.29231 36.5652 4.98644 36.5652C2.68056 36.5652 0.811279 38.4345 0.811279 40.7404C0.811279 43.0463 2.68056 44.9155 4.98644 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 44.9155C541.811 44.9155 543.681 43.0463 543.681 40.7404C543.681 38.4345 541.811 36.5652 539.505 36.5652C537.2 36.5652 535.33 38.4345 535.33 40.7404C535.33 43.0463 537.2 44.9155 539.505 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 44.9155C274.543 44.9155 276.412 43.0463 276.412 40.7404C276.412 38.4345 274.543 36.5652 272.237 36.5652C269.931 36.5652 268.062 38.4345 268.062 40.7404C268.062 43.0463 269.931 44.9155 272.237 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 44.9155C140.913 44.9155 142.782 43.0463 142.782 40.7404C142.782 38.4345 140.913 36.5652 138.607 36.5652C136.301 36.5652 134.432 38.4345 134.432 40.7404C134.432 43.0463 136.301 44.9155 138.607 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 44.9155C207.725 44.9155 209.595 43.0463 209.595 40.7404C209.595 38.4345 207.725 36.5652 205.419 36.5652C203.114 36.5652 201.244 38.4345 201.244 40.7404C201.244 43.0463 203.114 44.9155 205.419 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 44.9155C74.0955 44.9155 75.9648 43.0463 75.9648 40.7404C75.9648 38.4345 74.0955 36.5652 71.7896 36.5652C69.4838 36.5652 67.6145 38.4345 67.6145 40.7404C67.6145 43.0463 69.4838 44.9155 71.7896 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 44.9155C474.996 44.9155 476.865 43.0463 476.865 40.7404C476.865 38.4345 474.996 36.5652 472.69 36.5652C470.384 36.5652 468.515 38.4345 468.515 40.7404C468.515 43.0463 470.384 44.9155 472.69 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 44.9155C341.36 44.9155 343.23 43.0463 343.23 40.7404C343.23 38.4345 341.36 36.5652 339.055 36.5652C336.749 36.5652 334.879 38.4345 334.879 40.7404C334.879 43.0463 336.749 44.9155 339.055 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 44.9155C408.178 44.9155 410.047 43.0463 410.047 40.7404C410.047 38.4345 408.178 36.5652 405.872 36.5652C403.566 36.5652 401.697 38.4345 401.697 40.7404C401.697 43.0463 403.566 44.9155 405.872 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 81.4628C40.6912 81.4628 42.5605 79.5935 42.5605 77.2877C42.5605 74.9818 40.6912 73.1125 38.3854 73.1125C36.0795 73.1125 34.2102 74.9818 34.2102 77.2877C34.2102 79.5935 36.0795 81.4628 38.3854 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 81.4628C575.226 81.4628 577.096 79.5935 577.096 77.2877C577.096 74.9818 575.226 73.1125 572.921 73.1125C570.615 73.1125 568.745 74.9818 568.745 77.2877C568.745 79.5935 570.615 81.4628 572.921 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 81.4802C307.967 81.4802 309.843 79.6046 309.843 77.291C309.843 74.9773 307.967 73.1017 305.654 73.1017C303.34 73.1017 301.464 74.9773 301.464 77.291C301.464 79.6046 303.34 81.4802 305.654 81.4802Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 81.4628C174.326 81.4628 176.196 79.5935 176.196 77.2877C176.196 74.9818 174.326 73.1125 172.02 73.1125C169.715 73.1125 167.845 74.9818 167.845 77.2877C167.845 79.5935 169.715 81.4628 172.02 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 81.4628C241.142 81.4628 243.011 79.5935 243.011 77.2877C243.011 74.9818 241.142 73.1125 238.836 73.1125C236.53 73.1125 234.661 74.9818 234.661 77.2877C234.661 79.5935 236.53 81.4628 238.836 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 81.4628C107.509 81.4628 109.378 79.5935 109.378 77.2877C109.378 74.9818 107.509 73.1125 105.203 73.1125C102.897 73.1125 101.028 74.9818 101.028 77.2877C101.028 79.5935 102.897 81.4628 105.203 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 81.4628C508.409 81.4628 510.278 79.5935 510.278 77.2877C510.278 74.9818 508.409 73.1125 506.103 73.1125C503.797 73.1125 501.928 74.9818 501.928 77.2877C501.928 79.5935 503.797 81.4628 506.103 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 81.4802C374.785 81.4802 376.66 79.6046 376.66 77.291C376.66 74.9773 374.785 73.1017 372.471 73.1017C370.158 73.1017 368.282 74.9773 368.282 77.291C368.282 79.6046 370.158 81.4802 372.471 81.4802Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 81.4628C441.591 81.4628 443.461 79.5935 443.461 77.2877C443.461 74.9818 441.591 73.1125 439.285 73.1125C436.98 73.1125 435.11 74.9818 435.11 77.2877C435.11 79.5935 436.98 81.4628 439.285 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 81.4628C7.29231 81.4628 9.16159 79.5935 9.16159 77.2877C9.16159 74.9818 7.29231 73.1125 4.98644 73.1125C2.68056 73.1125 0.811279 74.9818 0.811279 77.2877C0.811279 79.5935 2.68056 81.4628 4.98644 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 81.4628C541.811 81.4628 543.681 79.5935 543.681 77.2877C543.681 74.9818 541.811 73.1125 539.505 73.1125C537.2 73.1125 535.33 74.9818 535.33 77.2877C535.33 79.5935 537.2 81.4628 539.505 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 81.4628C274.543 81.4628 276.412 79.5935 276.412 77.2877C276.412 74.9818 274.543 73.1125 272.237 73.1125C269.931 73.1125 268.062 74.9818 268.062 77.2877C268.062 79.5935 269.931 81.4628 272.237 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 81.4628C140.913 81.4628 142.782 79.5935 142.782 77.2877C142.782 74.9818 140.913 73.1125 138.607 73.1125C136.301 73.1125 134.432 74.9818 134.432 77.2877C134.432 79.5935 136.301 81.4628 138.607 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 81.4628C207.725 81.4628 209.595 79.5935 209.595 77.2877C209.595 74.9818 207.725 73.1125 205.419 73.1125C203.114 73.1125 201.244 74.9818 201.244 77.2877C201.244 79.5935 203.114 81.4628 205.419 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 81.4628C74.0955 81.4628 75.9648 79.5935 75.9648 77.2877C75.9648 74.9818 74.0955 73.1125 71.7896 73.1125C69.4838 73.1125 67.6145 74.9818 67.6145 77.2877C67.6145 79.5935 69.4838 81.4628 71.7896 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 81.4628C474.996 81.4628 476.865 79.5935 476.865 77.2877C476.865 74.9818 474.996 73.1125 472.69 73.1125C470.384 73.1125 468.515 74.9818 468.515 77.2877C468.515 79.5935 470.384 81.4628 472.69 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 81.4628C341.36 81.4628 343.23 79.5935 343.23 77.2877C343.23 74.9818 341.36 73.1125 339.055 73.1125C336.749 73.1125 334.879 74.9818 334.879 77.2877C334.879 79.5935 336.749 81.4628 339.055 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 81.4628C408.178 81.4628 410.047 79.5935 410.047 77.2877C410.047 74.9818 408.178 73.1125 405.872 73.1125C403.566 73.1125 401.697 74.9818 401.697 77.2877C401.697 79.5935 403.566 81.4628 405.872 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 118.016C40.6912 118.016 42.5605 116.146 42.5605 113.84C42.5605 111.534 40.6912 109.665 38.3854 109.665C36.0795 109.665 34.2102 111.534 34.2102 113.84C34.2102 116.146 36.0795 118.016 38.3854 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 118.016C575.226 118.016 577.096 116.146 577.096 113.84C577.096 111.534 575.226 109.665 572.921 109.665C570.615 109.665 568.745 111.534 568.745 113.84C568.745 116.146 570.615 118.016 572.921 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 118.027C307.967 118.027 309.843 116.152 309.843 113.838C309.843 111.525 307.967 109.649 305.654 109.649C303.34 109.649 301.464 111.525 301.464 113.838C301.464 116.152 303.34 118.027 305.654 118.027Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 118.016C174.326 118.016 176.196 116.146 176.196 113.84C176.196 111.534 174.326 109.665 172.02 109.665C169.715 109.665 167.845 111.534 167.845 113.84C167.845 116.146 169.715 118.016 172.02 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 118.016C241.142 118.016 243.011 116.146 243.011 113.84C243.011 111.534 241.142 109.665 238.836 109.665C236.53 109.665 234.661 111.534 234.661 113.84C234.661 116.146 236.53 118.016 238.836 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 118.016C107.509 118.016 109.378 116.146 109.378 113.84C109.378 111.534 107.509 109.665 105.203 109.665C102.897 109.665 101.028 111.534 101.028 113.84C101.028 116.146 102.897 118.016 105.203 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 118.016C508.409 118.016 510.278 116.146 510.278 113.84C510.278 111.534 508.409 109.665 506.103 109.665C503.797 109.665 501.928 111.534 501.928 113.84C501.928 116.146 503.797 118.016 506.103 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 118.027C374.785 118.027 376.66 116.152 376.66 113.838C376.66 111.525 374.785 109.649 372.471 109.649C370.158 109.649 368.282 111.525 368.282 113.838C368.282 116.152 370.158 118.027 372.471 118.027Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 118.016C441.591 118.016 443.461 116.146 443.461 113.84C443.461 111.534 441.591 109.665 439.285 109.665C436.98 109.665 435.11 111.534 435.11 113.84C435.11 116.146 436.98 118.016 439.285 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 118.016C7.29231 118.016 9.16159 116.146 9.16159 113.84C9.16159 111.534 7.29231 109.665 4.98644 109.665C2.68056 109.665 0.811279 111.534 0.811279 113.84C0.811279 116.146 2.68056 118.016 4.98644 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 118.016C541.811 118.016 543.681 116.146 543.681 113.84C543.681 111.534 541.811 109.665 539.505 109.665C537.2 109.665 535.33 111.534 535.33 113.84C535.33 116.146 537.2 118.016 539.505 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 118.016C274.543 118.016 276.412 116.146 276.412 113.84C276.412 111.534 274.543 109.665 272.237 109.665C269.931 109.665 268.062 111.534 268.062 113.84C268.062 116.146 269.931 118.016 272.237 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 118.016C140.913 118.016 142.782 116.146 142.782 113.84C142.782 111.534 140.913 109.665 138.607 109.665C136.301 109.665 134.432 111.534 134.432 113.84C134.432 116.146 136.301 118.016 138.607 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 118.016C207.725 118.016 209.595 116.146 209.595 113.84C209.595 111.534 207.725 109.665 205.419 109.665C203.114 109.665 201.244 111.534 201.244 113.84C201.244 116.146 203.114 118.016 205.419 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 118.016C74.0955 118.016 75.9648 116.146 75.9648 113.84C75.9648 111.534 74.0955 109.665 71.7896 109.665C69.4838 109.665 67.6145 111.534 67.6145 113.84C67.6145 116.146 69.4838 118.016 71.7896 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 118.016C474.996 118.016 476.865 116.146 476.865 113.84C476.865 111.534 474.996 109.665 472.69 109.665C470.384 109.665 468.515 111.534 468.515 113.84C468.515 116.146 470.384 118.016 472.69 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 118.016C341.36 118.016 343.23 116.146 343.23 113.84C343.23 111.534 341.36 109.665 339.055 109.665C336.749 109.665 334.879 111.534 334.879 113.84C334.879 116.146 336.749 118.016 339.055 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 118.016C408.178 118.016 410.047 116.146 410.047 113.84C410.047 111.534 408.178 109.665 405.872 109.665C403.566 109.665 401.697 111.534 401.697 113.84C401.697 116.146 403.566 118.016 405.872 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 154.565C40.6912 154.565 42.5605 152.695 42.5605 150.389C42.5605 148.084 40.6912 146.214 38.3854 146.214C36.0795 146.214 34.2102 148.084 34.2102 150.389C34.2102 152.695 36.0795 154.565 38.3854 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 154.565C575.226 154.565 577.096 152.695 577.096 150.389C577.096 148.084 575.226 146.214 572.921 146.214C570.615 146.214 568.745 148.084 568.745 150.389C568.745 152.695 570.615 154.565 572.921 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 154.58C307.967 154.58 309.843 152.705 309.843 150.391C309.843 148.077 307.967 146.202 305.654 146.202C303.34 146.202 301.464 148.077 301.464 150.391C301.464 152.705 303.34 154.58 305.654 154.58Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 154.565C174.326 154.565 176.196 152.695 176.196 150.389C176.196 148.084 174.326 146.214 172.02 146.214C169.715 146.214 167.845 148.084 167.845 150.389C167.845 152.695 169.715 154.565 172.02 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 154.565C241.142 154.565 243.011 152.695 243.011 150.389C243.011 148.084 241.142 146.214 238.836 146.214C236.53 146.214 234.661 148.084 234.661 150.389C234.661 152.695 236.53 154.565 238.836 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 154.565C107.509 154.565 109.378 152.695 109.378 150.389C109.378 148.084 107.509 146.214 105.203 146.214C102.897 146.214 101.028 148.084 101.028 150.389C101.028 152.695 102.897 154.565 105.203 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 154.565C508.409 154.565 510.278 152.695 510.278 150.389C510.278 148.084 508.409 146.214 506.103 146.214C503.797 146.214 501.928 148.084 501.928 150.389C501.928 152.695 503.797 154.565 506.103 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 154.58C374.785 154.58 376.66 152.705 376.66 150.391C376.66 148.077 374.785 146.202 372.471 146.202C370.158 146.202 368.282 148.077 368.282 150.391C368.282 152.705 370.158 154.58 372.471 154.58Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 154.565C441.591 154.565 443.461 152.695 443.461 150.389C443.461 148.084 441.591 146.214 439.285 146.214C436.98 146.214 435.11 148.084 435.11 150.389C435.11 152.695 436.98 154.565 439.285 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 154.565C7.29231 154.565 9.16159 152.695 9.16159 150.389C9.16159 148.084 7.29231 146.214 4.98644 146.214C2.68056 146.214 0.811279 148.084 0.811279 150.389C0.811279 152.695 2.68056 154.565 4.98644 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 154.565C541.811 154.565 543.681 152.695 543.681 150.389C543.681 148.084 541.811 146.214 539.505 146.214C537.2 146.214 535.33 148.084 535.33 150.389C535.33 152.695 537.2 154.565 539.505 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 154.565C274.543 154.565 276.412 152.695 276.412 150.389C276.412 148.084 274.543 146.214 272.237 146.214C269.931 146.214 268.062 148.084 268.062 150.389C268.062 152.695 269.931 154.565 272.237 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 154.565C140.913 154.565 142.782 152.695 142.782 150.389C142.782 148.084 140.913 146.214 138.607 146.214C136.301 146.214 134.432 148.084 134.432 150.389C134.432 152.695 136.301 154.565 138.607 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 154.565C207.725 154.565 209.595 152.695 209.595 150.389C209.595 148.084 207.725 146.214 205.419 146.214C203.114 146.214 201.244 148.084 201.244 150.389C201.244 152.695 203.114 154.565 205.419 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 154.565C74.0955 154.565 75.9648 152.695 75.9648 150.389C75.9648 148.084 74.0955 146.214 71.7896 146.214C69.4838 146.214 67.6145 148.084 67.6145 150.389C67.6145 152.695 69.4838 154.565 71.7896 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 154.565C474.996 154.565 476.865 152.695 476.865 150.389C476.865 148.084 474.996 146.214 472.69 146.214C470.384 146.214 468.515 148.084 468.515 150.389C468.515 152.695 470.384 154.565 472.69 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 154.565C341.36 154.565 343.23 152.695 343.23 150.389C343.23 148.084 341.36 146.214 339.055 146.214C336.749 146.214 334.879 148.084 334.879 150.389C334.879 152.695 336.749 154.565 339.055 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 154.565C408.178 154.565 410.047 152.695 410.047 150.389C410.047 148.084 408.178 146.214 405.872 146.214C403.566 146.214 401.697 148.084 401.697 150.389C401.697 152.695 403.566 154.565 405.872 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 191.115C40.6912 191.115 42.5605 189.246 42.5605 186.94C42.5605 184.634 40.6912 182.765 38.3854 182.765C36.0795 182.765 34.2102 184.634 34.2102 186.94C34.2102 189.246 36.0795 191.115 38.3854 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 191.115C575.226 191.115 577.096 189.246 577.096 186.94C577.096 184.634 575.226 182.765 572.921 182.765C570.615 182.765 568.745 184.634 568.745 186.94C568.745 189.246 570.615 191.115 572.921 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 191.129C307.967 191.129 309.843 189.254 309.843 186.94C309.843 184.626 307.967 182.751 305.654 182.751C303.34 182.751 301.464 184.626 301.464 186.94C301.464 189.254 303.34 191.129 305.654 191.129Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 191.115C174.326 191.115 176.196 189.246 176.196 186.94C176.196 184.634 174.326 182.765 172.02 182.765C169.715 182.765 167.845 184.634 167.845 186.94C167.845 189.246 169.715 191.115 172.02 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 191.115C241.142 191.115 243.011 189.246 243.011 186.94C243.011 184.634 241.142 182.765 238.836 182.765C236.53 182.765 234.661 184.634 234.661 186.94C234.661 189.246 236.53 191.115 238.836 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 191.115C107.509 191.115 109.378 189.246 109.378 186.94C109.378 184.634 107.509 182.765 105.203 182.765C102.897 182.765 101.028 184.634 101.028 186.94C101.028 189.246 102.897 191.115 105.203 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 191.115C508.409 191.115 510.278 189.246 510.278 186.94C510.278 184.634 508.409 182.765 506.103 182.765C503.797 182.765 501.928 184.634 501.928 186.94C501.928 189.246 503.797 191.115 506.103 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 191.129C374.785 191.129 376.66 189.254 376.66 186.94C376.66 184.626 374.785 182.751 372.471 182.751C370.158 182.751 368.282 184.626 368.282 186.94C368.282 189.254 370.158 191.129 372.471 191.129Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 191.115C441.591 191.115 443.461 189.246 443.461 186.94C443.461 184.634 441.591 182.765 439.285 182.765C436.98 182.765 435.11 184.634 435.11 186.94C435.11 189.246 436.98 191.115 439.285 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 191.115C7.29231 191.115 9.16159 189.246 9.16159 186.94C9.16159 184.634 7.29231 182.765 4.98644 182.765C2.68056 182.765 0.811279 184.634 0.811279 186.94C0.811279 189.246 2.68056 191.115 4.98644 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 191.115C541.811 191.115 543.681 189.246 543.681 186.94C543.681 184.634 541.811 182.765 539.505 182.765C537.2 182.765 535.33 184.634 535.33 186.94C535.33 189.246 537.2 191.115 539.505 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 191.115C274.543 191.115 276.412 189.246 276.412 186.94C276.412 184.634 274.543 182.765 272.237 182.765C269.931 182.765 268.062 184.634 268.062 186.94C268.062 189.246 269.931 191.115 272.237 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 191.115C140.913 191.115 142.782 189.246 142.782 186.94C142.782 184.634 140.913 182.765 138.607 182.765C136.301 182.765 134.432 184.634 134.432 186.94C134.432 189.246 136.301 191.115 138.607 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 191.115C207.725 191.115 209.595 189.246 209.595 186.94C209.595 184.634 207.725 182.765 205.419 182.765C203.114 182.765 201.244 184.634 201.244 186.94C201.244 189.246 203.114 191.115 205.419 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 191.115C74.0955 191.115 75.9648 189.246 75.9648 186.94C75.9648 184.634 74.0955 182.765 71.7896 182.765C69.4838 182.765 67.6145 184.634 67.6145 186.94C67.6145 189.246 69.4838 191.115 71.7896 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 191.115C474.996 191.115 476.865 189.246 476.865 186.94C476.865 184.634 474.996 182.765 472.69 182.765C470.384 182.765 468.515 184.634 468.515 186.94C468.515 189.246 470.384 191.115 472.69 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 191.115C341.36 191.115 343.23 189.246 343.23 186.94C343.23 184.634 341.36 182.765 339.055 182.765C336.749 182.765 334.879 184.634 334.879 186.94C334.879 189.246 336.749 191.115 339.055 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 191.115C408.178 191.115 410.047 189.246 410.047 186.94C410.047 184.634 408.178 182.765 405.872 182.765C403.566 182.765 401.697 184.634 401.697 186.94C401.697 189.246 403.566 191.115 405.872 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 227.666C40.6912 227.666 42.5605 225.797 42.5605 223.491C42.5605 221.185 40.6912 219.316 38.3854 219.316C36.0795 219.316 34.2102 221.185 34.2102 223.491C34.2102 225.797 36.0795 227.666 38.3854 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 227.666C575.226 227.666 577.096 225.797 577.096 223.491C577.096 221.185 575.226 219.316 572.921 219.316C570.615 219.316 568.745 221.185 568.745 223.491C568.745 225.797 570.615 227.666 572.921 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 227.682C307.967 227.682 309.843 225.806 309.843 223.493C309.843 221.179 307.967 219.303 305.654 219.303C303.34 219.303 301.464 221.179 301.464 223.493C301.464 225.806 303.34 227.682 305.654 227.682Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 227.666C174.326 227.666 176.196 225.797 176.196 223.491C176.196 221.185 174.326 219.316 172.02 219.316C169.715 219.316 167.845 221.185 167.845 223.491C167.845 225.797 169.715 227.666 172.02 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 227.666C241.142 227.666 243.011 225.797 243.011 223.491C243.011 221.185 241.142 219.316 238.836 219.316C236.53 219.316 234.661 221.185 234.661 223.491C234.661 225.797 236.53 227.666 238.836 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 227.666C107.509 227.666 109.378 225.797 109.378 223.491C109.378 221.185 107.509 219.316 105.203 219.316C102.897 219.316 101.028 221.185 101.028 223.491C101.028 225.797 102.897 227.666 105.203 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 227.666C508.409 227.666 510.278 225.797 510.278 223.491C510.278 221.185 508.409 219.316 506.103 219.316C503.797 219.316 501.928 221.185 501.928 223.491C501.928 225.797 503.797 227.666 506.103 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 227.682C374.785 227.682 376.66 225.806 376.66 223.493C376.66 221.179 374.785 219.303 372.471 219.303C370.158 219.303 368.282 221.179 368.282 223.493C368.282 225.806 370.158 227.682 372.471 227.682Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 227.666C441.591 227.666 443.461 225.797 443.461 223.491C443.461 221.185 441.591 219.316 439.285 219.316C436.98 219.316 435.11 221.185 435.11 223.491C435.11 225.797 436.98 227.666 439.285 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 227.666C7.29231 227.666 9.16159 225.797 9.16159 223.491C9.16159 221.185 7.29231 219.316 4.98644 219.316C2.68056 219.316 0.811279 221.185 0.811279 223.491C0.811279 225.797 2.68056 227.666 4.98644 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 227.666C541.811 227.666 543.681 225.797 543.681 223.491C543.681 221.185 541.811 219.316 539.505 219.316C537.2 219.316 535.33 221.185 535.33 223.491C535.33 225.797 537.2 227.666 539.505 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 227.666C274.543 227.666 276.412 225.797 276.412 223.491C276.412 221.185 274.543 219.316 272.237 219.316C269.931 219.316 268.062 221.185 268.062 223.491C268.062 225.797 269.931 227.666 272.237 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 227.666C140.913 227.666 142.782 225.797 142.782 223.491C142.782 221.185 140.913 219.316 138.607 219.316C136.301 219.316 134.432 221.185 134.432 223.491C134.432 225.797 136.301 227.666 138.607 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 227.666C207.725 227.666 209.595 225.797 209.595 223.491C209.595 221.185 207.725 219.316 205.419 219.316C203.114 219.316 201.244 221.185 201.244 223.491C201.244 225.797 203.114 227.666 205.419 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 227.666C74.0955 227.666 75.9648 225.797 75.9648 223.491C75.9648 221.185 74.0955 219.316 71.7896 219.316C69.4838 219.316 67.6145 221.185 67.6145 223.491C67.6145 225.797 69.4838 227.666 71.7896 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 227.666C474.996 227.666 476.865 225.797 476.865 223.491C476.865 221.185 474.996 219.316 472.69 219.316C470.384 219.316 468.515 221.185 468.515 223.491C468.515 225.797 470.384 227.666 472.69 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 227.666C341.36 227.666 343.23 225.797 343.23 223.491C343.23 221.185 341.36 219.316 339.055 219.316C336.749 219.316 334.879 221.185 334.879 223.491C334.879 225.797 336.749 227.666 339.055 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 227.666C408.178 227.666 410.047 225.797 410.047 223.491C410.047 221.185 408.178 219.316 405.872 219.316C403.566 219.316 401.697 221.185 401.697 223.491C401.697 225.797 403.566 227.666 405.872 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 264.214C40.6912 264.214 42.5605 262.344 42.5605 260.038C42.5605 257.733 40.6912 255.863 38.3854 255.863C36.0795 255.863 34.2102 257.733 34.2102 260.038C34.2102 262.344 36.0795 264.214 38.3854 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 264.214C575.226 264.214 577.096 262.344 577.096 260.038C577.096 257.733 575.226 255.863 572.921 255.863C570.615 255.863 568.745 257.733 568.745 260.038C568.745 262.344 570.615 264.214 572.921 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 264.231C307.967 264.231 309.843 262.355 309.843 260.042C309.843 257.728 307.967 255.853 305.654 255.853C303.34 255.853 301.464 257.728 301.464 260.042C301.464 262.355 303.34 264.231 305.654 264.231Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 264.214C174.326 264.214 176.196 262.344 176.196 260.038C176.196 257.733 174.326 255.863 172.02 255.863C169.715 255.863 167.845 257.733 167.845 260.038C167.845 262.344 169.715 264.214 172.02 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 264.214C241.142 264.214 243.011 262.344 243.011 260.038C243.011 257.733 241.142 255.863 238.836 255.863C236.53 255.863 234.661 257.733 234.661 260.038C234.661 262.344 236.53 264.214 238.836 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 264.214C107.509 264.214 109.378 262.344 109.378 260.038C109.378 257.733 107.509 255.863 105.203 255.863C102.897 255.863 101.028 257.733 101.028 260.038C101.028 262.344 102.897 264.214 105.203 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 264.214C508.409 264.214 510.278 262.344 510.278 260.038C510.278 257.733 508.409 255.863 506.103 255.863C503.797 255.863 501.928 257.733 501.928 260.038C501.928 262.344 503.797 264.214 506.103 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 264.231C374.785 264.231 376.66 262.355 376.66 260.042C376.66 257.728 374.785 255.853 372.471 255.853C370.158 255.853 368.282 257.728 368.282 260.042C368.282 262.355 370.158 264.231 372.471 264.231Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 264.214C441.591 264.214 443.461 262.344 443.461 260.038C443.461 257.733 441.591 255.863 439.285 255.863C436.98 255.863 435.11 257.733 435.11 260.038C435.11 262.344 436.98 264.214 439.285 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 264.214C7.29231 264.214 9.16159 262.344 9.16159 260.038C9.16159 257.733 7.29231 255.863 4.98644 255.863C2.68056 255.863 0.811279 257.733 0.811279 260.038C0.811279 262.344 2.68056 264.214 4.98644 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 264.214C541.811 264.214 543.681 262.344 543.681 260.038C543.681 257.733 541.811 255.863 539.505 255.863C537.2 255.863 535.33 257.733 535.33 260.038C535.33 262.344 537.2 264.214 539.505 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 264.214C274.543 264.214 276.412 262.344 276.412 260.038C276.412 257.733 274.543 255.863 272.237 255.863C269.931 255.863 268.062 257.733 268.062 260.038C268.062 262.344 269.931 264.214 272.237 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 264.214C140.913 264.214 142.782 262.344 142.782 260.038C142.782 257.733 140.913 255.863 138.607 255.863C136.301 255.863 134.432 257.733 134.432 260.038C134.432 262.344 136.301 264.214 138.607 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 264.214C207.725 264.214 209.595 262.344 209.595 260.038C209.595 257.733 207.725 255.863 205.419 255.863C203.114 255.863 201.244 257.733 201.244 260.038C201.244 262.344 203.114 264.214 205.419 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 264.214C74.0955 264.214 75.9648 262.344 75.9648 260.038C75.9648 257.733 74.0955 255.863 71.7896 255.863C69.4838 255.863 67.6145 257.733 67.6145 260.038C67.6145 262.344 69.4838 264.214 71.7896 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 264.214C474.996 264.214 476.865 262.344 476.865 260.038C476.865 257.733 474.996 255.863 472.69 255.863C470.384 255.863 468.515 257.733 468.515 260.038C468.515 262.344 470.384 264.214 472.69 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 264.214C341.36 264.214 343.23 262.344 343.23 260.038C343.23 257.733 341.36 255.863 339.055 255.863C336.749 255.863 334.879 257.733 334.879 260.038C334.879 262.344 336.749 264.214 339.055 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 264.214C408.178 264.214 410.047 262.344 410.047 260.038C410.047 257.733 408.178 255.863 405.872 255.863C403.566 255.863 401.697 257.733 401.697 260.038C401.697 262.344 403.566 264.214 405.872 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 300.766C40.6912 300.766 42.5605 298.897 42.5605 296.591C42.5605 294.285 40.6912 292.416 38.3854 292.416C36.0795 292.416 34.2102 294.285 34.2102 296.591C34.2102 298.897 36.0795 300.766 38.3854 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 300.766C575.226 300.766 577.096 298.897 577.096 296.591C577.096 294.285 575.226 292.416 572.921 292.416C570.615 292.416 568.745 294.285 568.745 296.591C568.745 298.897 570.615 300.766 572.921 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 300.778C307.967 300.778 309.843 298.903 309.843 296.589C309.843 294.275 307.967 292.4 305.654 292.4C303.34 292.4 301.464 294.275 301.464 296.589C301.464 298.903 303.34 300.778 305.654 300.778Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 300.766C174.326 300.766 176.196 298.897 176.196 296.591C176.196 294.285 174.326 292.416 172.02 292.416C169.715 292.416 167.845 294.285 167.845 296.591C167.845 298.897 169.715 300.766 172.02 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 300.766C241.142 300.766 243.011 298.897 243.011 296.591C243.011 294.285 241.142 292.416 238.836 292.416C236.53 292.416 234.661 294.285 234.661 296.591C234.661 298.897 236.53 300.766 238.836 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 300.766C107.509 300.766 109.378 298.897 109.378 296.591C109.378 294.285 107.509 292.416 105.203 292.416C102.897 292.416 101.028 294.285 101.028 296.591C101.028 298.897 102.897 300.766 105.203 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 300.766C508.409 300.766 510.278 298.897 510.278 296.591C510.278 294.285 508.409 292.416 506.103 292.416C503.797 292.416 501.928 294.285 501.928 296.591C501.928 298.897 503.797 300.766 506.103 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 300.778C374.785 300.778 376.66 298.903 376.66 296.589C376.66 294.275 374.785 292.4 372.471 292.4C370.158 292.4 368.282 294.275 368.282 296.589C368.282 298.903 370.158 300.778 372.471 300.778Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 300.766C441.591 300.766 443.461 298.897 443.461 296.591C443.461 294.285 441.591 292.416 439.285 292.416C436.98 292.416 435.11 294.285 435.11 296.591C435.11 298.897 436.98 300.766 439.285 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 300.766C7.29231 300.766 9.16159 298.897 9.16159 296.591C9.16159 294.285 7.29231 292.416 4.98644 292.416C2.68056 292.416 0.811279 294.285 0.811279 296.591C0.811279 298.897 2.68056 300.766 4.98644 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 300.766C541.811 300.766 543.681 298.897 543.681 296.591C543.681 294.285 541.811 292.416 539.505 292.416C537.2 292.416 535.33 294.285 535.33 296.591C535.33 298.897 537.2 300.766 539.505 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 300.766C274.543 300.766 276.412 298.897 276.412 296.591C276.412 294.285 274.543 292.416 272.237 292.416C269.931 292.416 268.062 294.285 268.062 296.591C268.062 298.897 269.931 300.766 272.237 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 300.766C140.913 300.766 142.782 298.897 142.782 296.591C142.782 294.285 140.913 292.416 138.607 292.416C136.301 292.416 134.432 294.285 134.432 296.591C134.432 298.897 136.301 300.766 138.607 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 300.766C207.725 300.766 209.595 298.897 209.595 296.591C209.595 294.285 207.725 292.416 205.419 292.416C203.114 292.416 201.244 294.285 201.244 296.591C201.244 298.897 203.114 300.766 205.419 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 300.766C74.0955 300.766 75.9648 298.897 75.9648 296.591C75.9648 294.285 74.0955 292.416 71.7896 292.416C69.4838 292.416 67.6145 294.285 67.6145 296.591C67.6145 298.897 69.4838 300.766 71.7896 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 300.766C474.996 300.766 476.865 298.897 476.865 296.591C476.865 294.285 474.996 292.416 472.69 292.416C470.384 292.416 468.515 294.285 468.515 296.591C468.515 298.897 470.384 300.766 472.69 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 300.766C341.36 300.766 343.23 298.897 343.23 296.591C343.23 294.285 341.36 292.416 339.055 292.416C336.749 292.416 334.879 294.285 334.879 296.591C334.879 298.897 336.749 300.766 339.055 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 300.766C408.178 300.766 410.047 298.897 410.047 296.591C410.047 294.285 408.178 292.416 405.872 292.416C403.566 292.416 401.697 294.285 401.697 296.591C401.697 298.897 403.566 300.766 405.872 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 337.315C40.6912 337.315 42.5605 335.446 42.5605 333.14C42.5605 330.834 40.6912 328.965 38.3854 328.965C36.0795 328.965 34.2102 330.834 34.2102 333.14C34.2102 335.446 36.0795 337.315 38.3854 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 337.315C575.226 337.315 577.096 335.446 577.096 333.14C577.096 330.834 575.226 328.965 572.921 328.965C570.615 328.965 568.745 330.834 568.745 333.14C568.745 335.446 570.615 337.315 572.921 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 337.331C307.967 337.331 309.843 335.455 309.843 333.142C309.843 330.828 307.967 328.952 305.654 328.952C303.34 328.952 301.464 330.828 301.464 333.142C301.464 335.455 303.34 337.331 305.654 337.331Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 337.315C174.326 337.315 176.196 335.446 176.196 333.14C176.196 330.834 174.326 328.965 172.02 328.965C169.715 328.965 167.845 330.834 167.845 333.14C167.845 335.446 169.715 337.315 172.02 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 337.315C241.142 337.315 243.011 335.446 243.011 333.14C243.011 330.834 241.142 328.965 238.836 328.965C236.53 328.965 234.661 330.834 234.661 333.14C234.661 335.446 236.53 337.315 238.836 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 337.315C107.509 337.315 109.378 335.446 109.378 333.14C109.378 330.834 107.509 328.965 105.203 328.965C102.897 328.965 101.028 330.834 101.028 333.14C101.028 335.446 102.897 337.315 105.203 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 337.315C508.409 337.315 510.278 335.446 510.278 333.14C510.278 330.834 508.409 328.965 506.103 328.965C503.797 328.965 501.928 330.834 501.928 333.14C501.928 335.446 503.797 337.315 506.103 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 337.331C374.785 337.331 376.66 335.455 376.66 333.142C376.66 330.828 374.785 328.952 372.471 328.952C370.158 328.952 368.282 330.828 368.282 333.142C368.282 335.455 370.158 337.331 372.471 337.331Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 337.315C441.591 337.315 443.461 335.446 443.461 333.14C443.461 330.834 441.591 328.965 439.285 328.965C436.98 328.965 435.11 330.834 435.11 333.14C435.11 335.446 436.98 337.315 439.285 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 337.315C7.29231 337.315 9.16159 335.446 9.16159 333.14C9.16159 330.834 7.29231 328.965 4.98644 328.965C2.68056 328.965 0.811279 330.834 0.811279 333.14C0.811279 335.446 2.68056 337.315 4.98644 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 337.315C541.811 337.315 543.681 335.446 543.681 333.14C543.681 330.834 541.811 328.965 539.505 328.965C537.2 328.965 535.33 330.834 535.33 333.14C535.33 335.446 537.2 337.315 539.505 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 337.315C274.543 337.315 276.412 335.446 276.412 333.14C276.412 330.834 274.543 328.965 272.237 328.965C269.931 328.965 268.062 330.834 268.062 333.14C268.062 335.446 269.931 337.315 272.237 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 337.315C140.913 337.315 142.782 335.446 142.782 333.14C142.782 330.834 140.913 328.965 138.607 328.965C136.301 328.965 134.432 330.834 134.432 333.14C134.432 335.446 136.301 337.315 138.607 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 337.315C207.725 337.315 209.595 335.446 209.595 333.14C209.595 330.834 207.725 328.965 205.419 328.965C203.114 328.965 201.244 330.834 201.244 333.14C201.244 335.446 203.114 337.315 205.419 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 337.315C74.0955 337.315 75.9648 335.446 75.9648 333.14C75.9648 330.834 74.0955 328.965 71.7896 328.965C69.4838 328.965 67.6145 330.834 67.6145 333.14C67.6145 335.446 69.4838 337.315 71.7896 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 337.315C474.996 337.315 476.865 335.446 476.865 333.14C476.865 330.834 474.996 328.965 472.69 328.965C470.384 328.965 468.515 330.834 468.515 333.14C468.515 335.446 470.384 337.315 472.69 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 337.315C341.36 337.315 343.23 335.446 343.23 333.14C343.23 330.834 341.36 328.965 339.055 328.965C336.749 328.965 334.879 330.834 334.879 333.14C334.879 335.446 336.749 337.315 339.055 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 337.315C408.178 337.315 410.047 335.446 410.047 333.14C410.047 330.834 408.178 328.965 405.872 328.965C403.566 328.965 401.697 330.834 401.697 333.14C401.697 335.446 403.566 337.315 405.872 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 373.866C40.6912 373.866 42.5605 371.997 42.5605 369.691C42.5605 367.385 40.6912 365.516 38.3854 365.516C36.0795 365.516 34.2102 367.385 34.2102 369.691C34.2102 371.997 36.0795 373.866 38.3854 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 373.866C575.226 373.866 577.096 371.997 577.096 369.691C577.096 367.385 575.226 365.516 572.921 365.516C570.615 365.516 568.745 367.385 568.745 369.691C568.745 371.997 570.615 373.866 572.921 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 373.88C307.967 373.88 309.843 372.004 309.843 369.691C309.843 367.377 307.967 365.502 305.654 365.502C303.34 365.502 301.464 367.377 301.464 369.691C301.464 372.004 303.34 373.88 305.654 373.88Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 373.866C174.326 373.866 176.196 371.997 176.196 369.691C176.196 367.385 174.326 365.516 172.02 365.516C169.715 365.516 167.845 367.385 167.845 369.691C167.845 371.997 169.715 373.866 172.02 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 373.866C241.142 373.866 243.011 371.997 243.011 369.691C243.011 367.385 241.142 365.516 238.836 365.516C236.53 365.516 234.661 367.385 234.661 369.691C234.661 371.997 236.53 373.866 238.836 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 373.866C107.509 373.866 109.378 371.997 109.378 369.691C109.378 367.385 107.509 365.516 105.203 365.516C102.897 365.516 101.028 367.385 101.028 369.691C101.028 371.997 102.897 373.866 105.203 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 373.866C508.409 373.866 510.278 371.997 510.278 369.691C510.278 367.385 508.409 365.516 506.103 365.516C503.797 365.516 501.928 367.385 501.928 369.691C501.928 371.997 503.797 373.866 506.103 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 373.88C374.785 373.88 376.66 372.004 376.66 369.691C376.66 367.377 374.785 365.502 372.471 365.502C370.158 365.502 368.282 367.377 368.282 369.691C368.282 372.004 370.158 373.88 372.471 373.88Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 373.866C441.591 373.866 443.461 371.997 443.461 369.691C443.461 367.385 441.591 365.516 439.285 365.516C436.98 365.516 435.11 367.385 435.11 369.691C435.11 371.997 436.98 373.866 439.285 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 373.866C7.29231 373.866 9.16159 371.997 9.16159 369.691C9.16159 367.385 7.29231 365.516 4.98644 365.516C2.68056 365.516 0.811279 367.385 0.811279 369.691C0.811279 371.997 2.68056 373.866 4.98644 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 373.866C541.811 373.866 543.681 371.997 543.681 369.691C543.681 367.385 541.811 365.516 539.505 365.516C537.2 365.516 535.33 367.385 535.33 369.691C535.33 371.997 537.2 373.866 539.505 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 373.866C274.543 373.866 276.412 371.997 276.412 369.691C276.412 367.385 274.543 365.516 272.237 365.516C269.931 365.516 268.062 367.385 268.062 369.691C268.062 371.997 269.931 373.866 272.237 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 373.866C140.913 373.866 142.782 371.997 142.782 369.691C142.782 367.385 140.913 365.516 138.607 365.516C136.301 365.516 134.432 367.385 134.432 369.691C134.432 371.997 136.301 373.866 138.607 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 373.866C207.725 373.866 209.595 371.997 209.595 369.691C209.595 367.385 207.725 365.516 205.419 365.516C203.114 365.516 201.244 367.385 201.244 369.691C201.244 371.997 203.114 373.866 205.419 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 373.866C74.0955 373.866 75.9648 371.997 75.9648 369.691C75.9648 367.385 74.0955 365.516 71.7896 365.516C69.4838 365.516 67.6145 367.385 67.6145 369.691C67.6145 371.997 69.4838 373.866 71.7896 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 373.866C474.996 373.866 476.865 371.997 476.865 369.691C476.865 367.385 474.996 365.516 472.69 365.516C470.384 365.516 468.515 367.385 468.515 369.691C468.515 371.997 470.384 373.866 472.69 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 373.866C341.36 373.866 343.23 371.997 343.23 369.691C343.23 367.385 341.36 365.516 339.055 365.516C336.749 365.516 334.879 367.385 334.879 369.691C334.879 371.997 336.749 373.866 339.055 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 373.866C408.178 373.866 410.047 371.997 410.047 369.691C410.047 367.385 408.178 365.516 405.872 365.516C403.566 365.516 401.697 367.385 401.697 369.691C401.697 371.997 403.566 373.866 405.872 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 410.415C40.6912 410.415 42.5605 408.546 42.5605 406.24C42.5605 403.934 40.6912 402.065 38.3854 402.065C36.0795 402.065 34.2102 403.934 34.2102 406.24C34.2102 408.546 36.0795 410.415 38.3854 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 410.433C575.232 410.433 577.108 408.557 577.108 406.243C577.108 403.93 575.232 402.054 572.918 402.054C570.605 402.054 568.729 403.93 568.729 406.243C568.729 408.557 570.605 410.433 572.918 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 410.433C307.967 410.433 309.843 408.557 309.843 406.243C309.843 403.93 307.967 402.054 305.654 402.054C303.34 402.054 301.464 403.93 301.464 406.243C301.464 408.557 303.34 410.433 305.654 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 410.415C174.326 410.415 176.196 408.546 176.196 406.24C176.196 403.934 174.326 402.065 172.02 402.065C169.715 402.065 167.845 403.934 167.845 406.24C167.845 408.546 169.715 410.415 172.02 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 410.433C241.151 410.433 243.027 408.557 243.027 406.243C243.027 403.93 241.151 402.054 238.838 402.054C236.524 402.054 234.649 403.93 234.649 406.243C234.649 408.557 236.524 410.433 238.838 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 410.415C107.509 410.415 109.378 408.546 109.378 406.24C109.378 403.934 107.509 402.065 105.203 402.065C102.897 402.065 101.028 403.934 101.028 406.24C101.028 408.546 102.897 410.415 105.203 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 410.433C508.418 410.433 510.294 408.557 510.294 406.243C510.294 403.93 508.418 402.054 506.104 402.054C503.791 402.054 501.915 403.93 501.915 406.243C501.915 408.557 503.791 410.433 506.104 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 410.433C374.785 410.433 376.66 408.557 376.66 406.243C376.66 403.93 374.785 402.054 372.471 402.054C370.158 402.054 368.282 403.93 368.282 406.243C368.282 408.557 370.158 410.433 372.471 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 410.433C441.599 410.433 443.474 408.557 443.474 406.243C443.474 403.93 441.599 402.054 439.285 402.054C436.971 402.054 435.096 403.93 435.096 406.243C435.096 408.557 436.971 410.433 439.285 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 410.433C7.29973 410.433 9.1753 408.557 9.1753 406.243C9.1753 403.93 7.29973 402.054 4.98609 402.054C2.67245 402.054 0.796875 403.93 0.796875 406.243C0.796875 408.557 2.67245 410.433 4.98609 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 410.433C541.819 410.433 543.694 408.557 543.694 406.243C543.694 403.93 541.819 402.054 539.505 402.054C537.192 402.054 535.316 403.93 535.316 406.243C535.316 408.557 537.192 410.433 539.505 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 410.433C274.55 410.433 276.426 408.557 276.426 406.243C276.426 403.93 274.55 402.054 272.237 402.054C269.923 402.054 268.047 403.93 268.047 406.243C268.047 408.557 269.923 410.433 272.237 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 410.415C140.913 410.415 142.782 408.546 142.782 406.24C142.782 403.934 140.913 402.065 138.607 402.065C136.301 402.065 134.432 403.934 134.432 406.24C134.432 408.546 136.301 410.415 138.607 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 410.415C207.725 410.415 209.595 408.546 209.595 406.24C209.595 403.934 207.725 402.065 205.419 402.065C203.114 402.065 201.244 403.934 201.244 406.24C201.244 408.546 203.114 410.415 205.419 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 410.433C74.1011 410.433 75.9767 408.557 75.9767 406.243C75.9767 403.93 74.1011 402.054 71.7875 402.054C69.4738 402.054 67.5983 403.93 67.5983 406.243C67.5983 408.557 69.4738 410.433 71.7875 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 410.433C475.001 410.433 476.877 408.557 476.877 406.243C476.877 403.93 475.001 402.054 472.688 402.054C470.374 402.054 468.498 403.93 468.498 406.243C468.498 408.557 470.374 410.433 472.688 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 410.433C341.37 410.433 343.245 408.557 343.245 406.243C343.245 403.93 341.37 402.054 339.056 402.054C336.742 402.054 334.867 403.93 334.867 406.243C334.867 408.557 336.742 410.433 339.056 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 410.433C408.184 410.433 410.059 408.557 410.059 406.243C410.059 403.93 408.184 402.054 405.87 402.054C403.556 402.054 401.681 403.93 401.681 406.243C401.681 408.557 403.556 410.433 405.87 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 446.968C40.6912 446.968 42.5605 445.099 42.5605 442.793C42.5605 440.487 40.6912 438.618 38.3854 438.618C36.0795 438.618 34.2102 440.487 34.2102 442.793C34.2102 445.099 36.0795 446.968 38.3854 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 446.982C575.232 446.982 577.108 445.106 577.108 442.793C577.108 440.479 575.232 438.603 572.918 438.603C570.605 438.603 568.729 440.479 568.729 442.793C568.729 445.106 570.605 446.982 572.918 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 446.982C307.967 446.982 309.843 445.106 309.843 442.793C309.843 440.479 307.967 438.603 305.654 438.603C303.34 438.603 301.464 440.479 301.464 442.793C301.464 445.106 303.34 446.982 305.654 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 446.968C174.326 446.968 176.196 445.099 176.196 442.793C176.196 440.487 174.326 438.618 172.02 438.618C169.715 438.618 167.845 440.487 167.845 442.793C167.845 445.099 169.715 446.968 172.02 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 446.982C241.151 446.982 243.027 445.106 243.027 442.793C243.027 440.479 241.151 438.603 238.838 438.603C236.524 438.603 234.649 440.479 234.649 442.793C234.649 445.106 236.524 446.982 238.838 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 446.968C107.509 446.968 109.378 445.099 109.378 442.793C109.378 440.487 107.509 438.618 105.203 438.618C102.897 438.618 101.028 440.487 101.028 442.793C101.028 445.099 102.897 446.968 105.203 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 446.982C508.418 446.982 510.294 445.106 510.294 442.793C510.294 440.479 508.418 438.603 506.104 438.603C503.791 438.603 501.915 440.479 501.915 442.793C501.915 445.106 503.791 446.982 506.104 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 446.982C374.785 446.982 376.66 445.106 376.66 442.793C376.66 440.479 374.785 438.603 372.471 438.603C370.158 438.603 368.282 440.479 368.282 442.793C368.282 445.106 370.158 446.982 372.471 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 446.982C441.599 446.982 443.474 445.106 443.474 442.793C443.474 440.479 441.599 438.603 439.285 438.603C436.971 438.603 435.096 440.479 435.096 442.793C435.096 445.106 436.971 446.982 439.285 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 446.982C7.29973 446.982 9.1753 445.106 9.1753 442.793C9.1753 440.479 7.29973 438.603 4.98609 438.603C2.67245 438.603 0.796875 440.479 0.796875 442.793C0.796875 445.106 2.67245 446.982 4.98609 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 446.982C541.819 446.982 543.694 445.106 543.694 442.793C543.694 440.479 541.819 438.603 539.505 438.603C537.192 438.603 535.316 440.479 535.316 442.793C535.316 445.106 537.192 446.982 539.505 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 446.982C274.55 446.982 276.426 445.106 276.426 442.793C276.426 440.479 274.55 438.603 272.237 438.603C269.923 438.603 268.047 440.479 268.047 442.793C268.047 445.106 269.923 446.982 272.237 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 446.968C140.913 446.968 142.782 445.099 142.782 442.793C142.782 440.487 140.913 438.618 138.607 438.618C136.301 438.618 134.432 440.487 134.432 442.793C134.432 445.099 136.301 446.968 138.607 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 446.968C207.725 446.968 209.595 445.099 209.595 442.793C209.595 440.487 207.725 438.618 205.419 438.618C203.114 438.618 201.244 440.487 201.244 442.793C201.244 445.099 203.114 446.968 205.419 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 446.982C74.1011 446.982 75.9767 445.106 75.9767 442.793C75.9767 440.479 74.1011 438.603 71.7875 438.603C69.4738 438.603 67.5983 440.479 67.5983 442.793C67.5983 445.106 69.4738 446.982 71.7875 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 446.982C475.001 446.982 476.877 445.106 476.877 442.793C476.877 440.479 475.001 438.603 472.688 438.603C470.374 438.603 468.498 440.479 468.498 442.793C468.498 445.106 470.374 446.982 472.688 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 446.982C341.37 446.982 343.245 445.106 343.245 442.793C343.245 440.479 341.37 438.603 339.056 438.603C336.742 438.603 334.867 440.479 334.867 442.793C334.867 445.106 336.742 446.982 339.056 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 446.982C408.184 446.982 410.059 445.106 410.059 442.793C410.059 440.479 408.184 438.603 405.87 438.603C403.556 438.603 401.681 440.479 401.681 442.793C401.681 445.106 403.556 446.982 405.87 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 483.515C40.6912 483.515 42.5605 481.646 42.5605 479.34C42.5605 477.034 40.6912 475.165 38.3854 475.165C36.0795 475.165 34.2102 477.034 34.2102 479.34C34.2102 481.646 36.0795 483.515 38.3854 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 483.529C575.232 483.529 577.108 481.653 577.108 479.34C577.108 477.026 575.232 475.151 572.918 475.151C570.605 475.151 568.729 477.026 568.729 479.34C568.729 481.653 570.605 483.529 572.918 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 483.529C307.967 483.529 309.843 481.653 309.843 479.34C309.843 477.026 307.967 475.151 305.654 475.151C303.34 475.151 301.464 477.026 301.464 479.34C301.464 481.653 303.34 483.529 305.654 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 483.515C174.326 483.515 176.196 481.646 176.196 479.34C176.196 477.034 174.326 475.165 172.02 475.165C169.715 475.165 167.845 477.034 167.845 479.34C167.845 481.646 169.715 483.515 172.02 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 483.529C241.151 483.529 243.027 481.653 243.027 479.34C243.027 477.026 241.151 475.151 238.838 475.151C236.524 475.151 234.649 477.026 234.649 479.34C234.649 481.653 236.524 483.529 238.838 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 483.515C107.509 483.515 109.378 481.646 109.378 479.34C109.378 477.034 107.509 475.165 105.203 475.165C102.897 475.165 101.028 477.034 101.028 479.34C101.028 481.646 102.897 483.515 105.203 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 483.529C508.418 483.529 510.294 481.653 510.294 479.34C510.294 477.026 508.418 475.151 506.104 475.151C503.791 475.151 501.915 477.026 501.915 479.34C501.915 481.653 503.791 483.529 506.104 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 483.529C374.785 483.529 376.66 481.653 376.66 479.34C376.66 477.026 374.785 475.151 372.471 475.151C370.158 475.151 368.282 477.026 368.282 479.34C368.282 481.653 370.158 483.529 372.471 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 483.529C441.599 483.529 443.474 481.653 443.474 479.34C443.474 477.026 441.599 475.151 439.285 475.151C436.971 475.151 435.096 477.026 435.096 479.34C435.096 481.653 436.971 483.529 439.285 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 483.529C7.29973 483.529 9.1753 481.653 9.1753 479.34C9.1753 477.026 7.29973 475.151 4.98609 475.151C2.67245 475.151 0.796875 477.026 0.796875 479.34C0.796875 481.653 2.67245 483.529 4.98609 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 483.529C541.819 483.529 543.694 481.653 543.694 479.34C543.694 477.026 541.819 475.151 539.505 475.151C537.192 475.151 535.316 477.026 535.316 479.34C535.316 481.653 537.192 483.529 539.505 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 483.529C274.55 483.529 276.426 481.653 276.426 479.34C276.426 477.026 274.55 475.151 272.237 475.151C269.923 475.151 268.047 477.026 268.047 479.34C268.047 481.653 269.923 483.529 272.237 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 483.515C140.913 483.515 142.782 481.646 142.782 479.34C142.782 477.034 140.913 475.165 138.607 475.165C136.301 475.165 134.432 477.034 134.432 479.34C134.432 481.646 136.301 483.515 138.607 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 483.515C207.725 483.515 209.595 481.646 209.595 479.34C209.595 477.034 207.725 475.165 205.419 475.165C203.114 475.165 201.244 477.034 201.244 479.34C201.244 481.646 203.114 483.515 205.419 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 483.529C74.1011 483.529 75.9767 481.653 75.9767 479.34C75.9767 477.026 74.1011 475.151 71.7875 475.151C69.4738 475.151 67.5983 477.026 67.5983 479.34C67.5983 481.653 69.4738 483.529 71.7875 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 483.529C475.001 483.529 476.877 481.653 476.877 479.34C476.877 477.026 475.001 475.151 472.688 475.151C470.374 475.151 468.498 477.026 468.498 479.34C468.498 481.653 470.374 483.529 472.688 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 483.529C341.37 483.529 343.245 481.653 343.245 479.34C343.245 477.026 341.37 475.151 339.056 475.151C336.742 475.151 334.867 477.026 334.867 479.34C334.867 481.653 336.742 483.529 339.056 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 483.529C408.184 483.529 410.059 481.653 410.059 479.34C410.059 477.026 408.184 475.151 405.87 475.151C403.556 475.151 401.681 477.026 401.681 479.34C401.681 481.653 403.556 483.529 405.87 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 520.066C40.6912 520.066 42.5605 518.197 42.5605 515.891C42.5605 513.585 40.6912 511.716 38.3854 511.716C36.0795 511.716 34.2102 513.585 34.2102 515.891C34.2102 518.197 36.0795 520.066 38.3854 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 520.082C575.232 520.082 577.108 518.206 577.108 515.892C577.108 513.579 575.232 511.703 572.918 511.703C570.605 511.703 568.729 513.579 568.729 515.892C568.729 518.206 570.605 520.082 572.918 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 520.082C307.967 520.082 309.843 518.206 309.843 515.892C309.843 513.579 307.967 511.703 305.654 511.703C303.34 511.703 301.464 513.579 301.464 515.892C301.464 518.206 303.34 520.082 305.654 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 520.066C174.326 520.066 176.196 518.197 176.196 515.891C176.196 513.585 174.326 511.716 172.02 511.716C169.715 511.716 167.845 513.585 167.845 515.891C167.845 518.197 169.715 520.066 172.02 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 520.082C241.151 520.082 243.027 518.206 243.027 515.892C243.027 513.579 241.151 511.703 238.838 511.703C236.524 511.703 234.649 513.579 234.649 515.892C234.649 518.206 236.524 520.082 238.838 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 520.066C107.509 520.066 109.378 518.197 109.378 515.891C109.378 513.585 107.509 511.716 105.203 511.716C102.897 511.716 101.028 513.585 101.028 515.891C101.028 518.197 102.897 520.066 105.203 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 520.082C508.418 520.082 510.294 518.206 510.294 515.892C510.294 513.579 508.418 511.703 506.104 511.703C503.791 511.703 501.915 513.579 501.915 515.892C501.915 518.206 503.791 520.082 506.104 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 520.082C374.785 520.082 376.66 518.206 376.66 515.892C376.66 513.579 374.785 511.703 372.471 511.703C370.158 511.703 368.282 513.579 368.282 515.892C368.282 518.206 370.158 520.082 372.471 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 520.082C441.599 520.082 443.474 518.206 443.474 515.892C443.474 513.579 441.599 511.703 439.285 511.703C436.971 511.703 435.096 513.579 435.096 515.892C435.096 518.206 436.971 520.082 439.285 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 520.082C7.29973 520.082 9.1753 518.206 9.1753 515.892C9.1753 513.579 7.29973 511.703 4.98609 511.703C2.67245 511.703 0.796875 513.579 0.796875 515.892C0.796875 518.206 2.67245 520.082 4.98609 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 520.082C541.819 520.082 543.694 518.206 543.694 515.892C543.694 513.579 541.819 511.703 539.505 511.703C537.192 511.703 535.316 513.579 535.316 515.892C535.316 518.206 537.192 520.082 539.505 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 520.082C274.55 520.082 276.426 518.206 276.426 515.892C276.426 513.579 274.55 511.703 272.237 511.703C269.923 511.703 268.047 513.579 268.047 515.892C268.047 518.206 269.923 520.082 272.237 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 520.066C140.913 520.066 142.782 518.197 142.782 515.891C142.782 513.585 140.913 511.716 138.607 511.716C136.301 511.716 134.432 513.585 134.432 515.891C134.432 518.197 136.301 520.066 138.607 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 520.066C207.725 520.066 209.595 518.197 209.595 515.891C209.595 513.585 207.725 511.716 205.419 511.716C203.114 511.716 201.244 513.585 201.244 515.891C201.244 518.197 203.114 520.066 205.419 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 520.082C74.1011 520.082 75.9767 518.206 75.9767 515.892C75.9767 513.579 74.1011 511.703 71.7875 511.703C69.4738 511.703 67.5983 513.579 67.5983 515.892C67.5983 518.206 69.4738 520.082 71.7875 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 520.082C475.001 520.082 476.877 518.206 476.877 515.892C476.877 513.579 475.001 511.703 472.688 511.703C470.374 511.703 468.498 513.579 468.498 515.892C468.498 518.206 470.374 520.082 472.688 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 520.082C341.37 520.082 343.245 518.206 343.245 515.892C343.245 513.579 341.37 511.703 339.056 511.703C336.742 511.703 334.867 513.579 334.867 515.892C334.867 518.206 336.742 520.082 339.056 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 520.082C408.184 520.082 410.059 518.206 410.059 515.892C410.059 513.579 408.184 511.703 405.87 511.703C403.556 511.703 401.681 513.579 401.681 515.892C401.681 518.206 403.556 520.082 405.87 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 556.617C40.6912 556.617 42.5605 554.748 42.5605 552.442C42.5605 550.136 40.6912 548.267 38.3854 548.267C36.0795 548.267 34.2102 550.136 34.2102 552.442C34.2102 554.748 36.0795 556.617 38.3854 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 556.631C575.232 556.631 577.108 554.755 577.108 552.442C577.108 550.128 575.232 548.252 572.918 548.252C570.605 548.252 568.729 550.128 568.729 552.442C568.729 554.755 570.605 556.631 572.918 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 556.631C307.967 556.631 309.843 554.755 309.843 552.442C309.843 550.128 307.967 548.252 305.654 548.252C303.34 548.252 301.464 550.128 301.464 552.442C301.464 554.755 303.34 556.631 305.654 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 556.617C174.326 556.617 176.196 554.748 176.196 552.442C176.196 550.136 174.326 548.267 172.02 548.267C169.715 548.267 167.845 550.136 167.845 552.442C167.845 554.748 169.715 556.617 172.02 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 556.631C241.151 556.631 243.027 554.755 243.027 552.442C243.027 550.128 241.151 548.252 238.838 548.252C236.524 548.252 234.649 550.128 234.649 552.442C234.649 554.755 236.524 556.631 238.838 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 556.617C107.509 556.617 109.378 554.748 109.378 552.442C109.378 550.136 107.509 548.267 105.203 548.267C102.897 548.267 101.028 550.136 101.028 552.442C101.028 554.748 102.897 556.617 105.203 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 556.631C508.418 556.631 510.294 554.755 510.294 552.442C510.294 550.128 508.418 548.252 506.104 548.252C503.791 548.252 501.915 550.128 501.915 552.442C501.915 554.755 503.791 556.631 506.104 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 556.631C374.785 556.631 376.66 554.755 376.66 552.442C376.66 550.128 374.785 548.252 372.471 548.252C370.158 548.252 368.282 550.128 368.282 552.442C368.282 554.755 370.158 556.631 372.471 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 556.631C441.599 556.631 443.474 554.755 443.474 552.442C443.474 550.128 441.599 548.252 439.285 548.252C436.971 548.252 435.096 550.128 435.096 552.442C435.096 554.755 436.971 556.631 439.285 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 556.631C7.29973 556.631 9.1753 554.755 9.1753 552.442C9.1753 550.128 7.29973 548.252 4.98609 548.252C2.67245 548.252 0.796875 550.128 0.796875 552.442C0.796875 554.755 2.67245 556.631 4.98609 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 556.631C541.819 556.631 543.694 554.755 543.694 552.442C543.694 550.128 541.819 548.252 539.505 548.252C537.192 548.252 535.316 550.128 535.316 552.442C535.316 554.755 537.192 556.631 539.505 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 556.631C274.55 556.631 276.426 554.755 276.426 552.442C276.426 550.128 274.55 548.252 272.237 548.252C269.923 548.252 268.047 550.128 268.047 552.442C268.047 554.755 269.923 556.631 272.237 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 556.617C140.913 556.617 142.782 554.748 142.782 552.442C142.782 550.136 140.913 548.267 138.607 548.267C136.301 548.267 134.432 550.136 134.432 552.442C134.432 554.748 136.301 556.617 138.607 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 556.617C207.725 556.617 209.595 554.748 209.595 552.442C209.595 550.136 207.725 548.267 205.419 548.267C203.114 548.267 201.244 550.136 201.244 552.442C201.244 554.748 203.114 556.617 205.419 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 556.631C74.1011 556.631 75.9767 554.755 75.9767 552.442C75.9767 550.128 74.1011 548.252 71.7875 548.252C69.4738 548.252 67.5983 550.128 67.5983 552.442C67.5983 554.755 69.4738 556.631 71.7875 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 556.631C475.001 556.631 476.877 554.755 476.877 552.442C476.877 550.128 475.001 548.252 472.688 548.252C470.374 548.252 468.498 550.128 468.498 552.442C468.498 554.755 470.374 556.631 472.688 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 556.631C341.37 556.631 343.245 554.755 343.245 552.442C343.245 550.128 341.37 548.252 339.056 548.252C336.742 548.252 334.867 550.128 334.867 552.442C334.867 554.755 336.742 556.631 339.056 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 556.631C408.184 556.631 410.059 554.755 410.059 552.442C410.059 550.128 408.184 548.252 405.87 548.252C403.556 548.252 401.681 550.128 401.681 552.442C401.681 554.755 403.556 556.631 405.87 556.631Z"
              fill={svgbgcolor}
            />
          </pattern>

          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-checkers)"
          ></rect>
        </svg>
      </div>

      <div className="bg-top">
        <div className="bg-end" />

        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <pattern
            id="pattern-checkers"
            x="0"
            y="0"
            width="603"
            height="588"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M38.3854 8.36466C40.6912 8.36466 42.5605 6.49538 42.5605 4.1895C42.5605 1.88363 40.6912 0.0143433 38.3854 0.0143433C36.0795 0.0143433 34.2102 1.88363 34.2102 4.1895C34.2102 6.49538 36.0795 8.36466 38.3854 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 8.36466C575.226 8.36466 577.096 6.49538 577.096 4.1895C577.096 1.88363 575.226 0.0143433 572.921 0.0143433C570.615 0.0143433 568.745 1.88363 568.745 4.1895C568.745 6.49538 570.615 8.36466 572.921 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 8.3784C307.967 8.3784 309.843 6.50285 309.843 4.18922C309.843 1.87558 307.967 -1.52588e-05 305.654 -1.52588e-05C303.34 -1.52588e-05 301.464 1.87558 301.464 4.18922C301.464 6.50285 303.34 8.3784 305.654 8.3784Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 8.36466C174.326 8.36466 176.196 6.49538 176.196 4.1895C176.196 1.88363 174.326 0.0143433 172.02 0.0143433C169.715 0.0143433 167.845 1.88363 167.845 4.1895C167.845 6.49538 169.715 8.36466 172.02 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 8.36466C241.142 8.36466 243.011 6.49538 243.011 4.1895C243.011 1.88363 241.142 0.0143433 238.836 0.0143433C236.53 0.0143433 234.661 1.88363 234.661 4.1895C234.661 6.49538 236.53 8.36466 238.836 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 8.36466C107.509 8.36466 109.378 6.49538 109.378 4.1895C109.378 1.88363 107.509 0.0143433 105.203 0.0143433C102.897 0.0143433 101.028 1.88363 101.028 4.1895C101.028 6.49538 102.897 8.36466 105.203 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 8.36466C508.409 8.36466 510.278 6.49538 510.278 4.1895C510.278 1.88363 508.409 0.0143433 506.103 0.0143433C503.797 0.0143433 501.928 1.88363 501.928 4.1895C501.928 6.49538 503.797 8.36466 506.103 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 8.3784C374.785 8.3784 376.66 6.50285 376.66 4.18922C376.66 1.87558 374.785 -1.52588e-05 372.471 -1.52588e-05C370.158 -1.52588e-05 368.282 1.87558 368.282 4.18922C368.282 6.50285 370.158 8.3784 372.471 8.3784Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 8.36466C441.591 8.36466 443.461 6.49538 443.461 4.1895C443.461 1.88363 441.591 0.0143433 439.285 0.0143433C436.98 0.0143433 435.11 1.88363 435.11 4.1895C435.11 6.49538 436.98 8.36466 439.285 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 8.36466C7.29231 8.36466 9.16159 6.49538 9.16159 4.1895C9.16159 1.88363 7.29231 0.0143433 4.98644 0.0143433C2.68056 0.0143433 0.811279 1.88363 0.811279 4.1895C0.811279 6.49538 2.68056 8.36466 4.98644 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 8.36466C541.811 8.36466 543.681 6.49538 543.681 4.1895C543.681 1.88363 541.811 0.0143433 539.505 0.0143433C537.2 0.0143433 535.33 1.88363 535.33 4.1895C535.33 6.49538 537.2 8.36466 539.505 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 8.36466C274.543 8.36466 276.412 6.49538 276.412 4.1895C276.412 1.88363 274.543 0.0143433 272.237 0.0143433C269.931 0.0143433 268.062 1.88363 268.062 4.1895C268.062 6.49538 269.931 8.36466 272.237 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 8.36466C140.913 8.36466 142.782 6.49538 142.782 4.1895C142.782 1.88363 140.913 0.0143433 138.607 0.0143433C136.301 0.0143433 134.432 1.88363 134.432 4.1895C134.432 6.49538 136.301 8.36466 138.607 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 8.36466C207.725 8.36466 209.595 6.49538 209.595 4.1895C209.595 1.88363 207.725 0.0143433 205.419 0.0143433C203.114 0.0143433 201.244 1.88363 201.244 4.1895C201.244 6.49538 203.114 8.36466 205.419 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 8.36466C74.0955 8.36466 75.9648 6.49538 75.9648 4.1895C75.9648 1.88363 74.0955 0.0143433 71.7896 0.0143433C69.4838 0.0143433 67.6145 1.88363 67.6145 4.1895C67.6145 6.49538 69.4838 8.36466 71.7896 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 8.36466C474.996 8.36466 476.865 6.49538 476.865 4.1895C476.865 1.88363 474.996 0.0143433 472.69 0.0143433C470.384 0.0143433 468.515 1.88363 468.515 4.1895C468.515 6.49538 470.384 8.36466 472.69 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 8.36466C341.36 8.36466 343.23 6.49538 343.23 4.1895C343.23 1.88363 341.36 0.0143433 339.055 0.0143433C336.749 0.0143433 334.879 1.88363 334.879 4.1895C334.879 6.49538 336.749 8.36466 339.055 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 8.36466C408.178 8.36466 410.047 6.49538 410.047 4.1895C410.047 1.88363 408.178 0.0143433 405.872 0.0143433C403.566 0.0143433 401.697 1.88363 401.697 4.1895C401.697 6.49538 403.566 8.36466 405.872 8.36466Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 44.9155C40.6912 44.9155 42.5605 43.0463 42.5605 40.7404C42.5605 38.4345 40.6912 36.5652 38.3854 36.5652C36.0795 36.5652 34.2102 38.4345 34.2102 40.7404C34.2102 43.0463 36.0795 44.9155 38.3854 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 44.9155C575.226 44.9155 577.096 43.0463 577.096 40.7404C577.096 38.4345 575.226 36.5652 572.921 36.5652C570.615 36.5652 568.745 38.4345 568.745 40.7404C568.745 43.0463 570.615 44.9155 572.921 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 44.9275C307.967 44.9275 309.843 43.0519 309.843 40.7383C309.843 38.4247 307.967 36.5491 305.654 36.5491C303.34 36.5491 301.464 38.4247 301.464 40.7383C301.464 43.0519 303.34 44.9275 305.654 44.9275Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 44.9155C174.326 44.9155 176.196 43.0463 176.196 40.7404C176.196 38.4345 174.326 36.5652 172.02 36.5652C169.715 36.5652 167.845 38.4345 167.845 40.7404C167.845 43.0463 169.715 44.9155 172.02 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 44.9155C241.142 44.9155 243.011 43.0463 243.011 40.7404C243.011 38.4345 241.142 36.5652 238.836 36.5652C236.53 36.5652 234.661 38.4345 234.661 40.7404C234.661 43.0463 236.53 44.9155 238.836 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 44.9155C107.509 44.9155 109.378 43.0463 109.378 40.7404C109.378 38.4345 107.509 36.5652 105.203 36.5652C102.897 36.5652 101.028 38.4345 101.028 40.7404C101.028 43.0463 102.897 44.9155 105.203 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 44.9155C508.409 44.9155 510.278 43.0463 510.278 40.7404C510.278 38.4345 508.409 36.5652 506.103 36.5652C503.797 36.5652 501.928 38.4345 501.928 40.7404C501.928 43.0463 503.797 44.9155 506.103 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 44.9275C374.785 44.9275 376.66 43.0519 376.66 40.7383C376.66 38.4247 374.785 36.5491 372.471 36.5491C370.158 36.5491 368.282 38.4247 368.282 40.7383C368.282 43.0519 370.158 44.9275 372.471 44.9275Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 44.9155C441.591 44.9155 443.461 43.0463 443.461 40.7404C443.461 38.4345 441.591 36.5652 439.285 36.5652C436.98 36.5652 435.11 38.4345 435.11 40.7404C435.11 43.0463 436.98 44.9155 439.285 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 44.9155C7.29231 44.9155 9.16159 43.0463 9.16159 40.7404C9.16159 38.4345 7.29231 36.5652 4.98644 36.5652C2.68056 36.5652 0.811279 38.4345 0.811279 40.7404C0.811279 43.0463 2.68056 44.9155 4.98644 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 44.9155C541.811 44.9155 543.681 43.0463 543.681 40.7404C543.681 38.4345 541.811 36.5652 539.505 36.5652C537.2 36.5652 535.33 38.4345 535.33 40.7404C535.33 43.0463 537.2 44.9155 539.505 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 44.9155C274.543 44.9155 276.412 43.0463 276.412 40.7404C276.412 38.4345 274.543 36.5652 272.237 36.5652C269.931 36.5652 268.062 38.4345 268.062 40.7404C268.062 43.0463 269.931 44.9155 272.237 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 44.9155C140.913 44.9155 142.782 43.0463 142.782 40.7404C142.782 38.4345 140.913 36.5652 138.607 36.5652C136.301 36.5652 134.432 38.4345 134.432 40.7404C134.432 43.0463 136.301 44.9155 138.607 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 44.9155C207.725 44.9155 209.595 43.0463 209.595 40.7404C209.595 38.4345 207.725 36.5652 205.419 36.5652C203.114 36.5652 201.244 38.4345 201.244 40.7404C201.244 43.0463 203.114 44.9155 205.419 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 44.9155C74.0955 44.9155 75.9648 43.0463 75.9648 40.7404C75.9648 38.4345 74.0955 36.5652 71.7896 36.5652C69.4838 36.5652 67.6145 38.4345 67.6145 40.7404C67.6145 43.0463 69.4838 44.9155 71.7896 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 44.9155C474.996 44.9155 476.865 43.0463 476.865 40.7404C476.865 38.4345 474.996 36.5652 472.69 36.5652C470.384 36.5652 468.515 38.4345 468.515 40.7404C468.515 43.0463 470.384 44.9155 472.69 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 44.9155C341.36 44.9155 343.23 43.0463 343.23 40.7404C343.23 38.4345 341.36 36.5652 339.055 36.5652C336.749 36.5652 334.879 38.4345 334.879 40.7404C334.879 43.0463 336.749 44.9155 339.055 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 44.9155C408.178 44.9155 410.047 43.0463 410.047 40.7404C410.047 38.4345 408.178 36.5652 405.872 36.5652C403.566 36.5652 401.697 38.4345 401.697 40.7404C401.697 43.0463 403.566 44.9155 405.872 44.9155Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 81.4628C40.6912 81.4628 42.5605 79.5935 42.5605 77.2877C42.5605 74.9818 40.6912 73.1125 38.3854 73.1125C36.0795 73.1125 34.2102 74.9818 34.2102 77.2877C34.2102 79.5935 36.0795 81.4628 38.3854 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 81.4628C575.226 81.4628 577.096 79.5935 577.096 77.2877C577.096 74.9818 575.226 73.1125 572.921 73.1125C570.615 73.1125 568.745 74.9818 568.745 77.2877C568.745 79.5935 570.615 81.4628 572.921 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 81.4802C307.967 81.4802 309.843 79.6046 309.843 77.291C309.843 74.9773 307.967 73.1017 305.654 73.1017C303.34 73.1017 301.464 74.9773 301.464 77.291C301.464 79.6046 303.34 81.4802 305.654 81.4802Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 81.4628C174.326 81.4628 176.196 79.5935 176.196 77.2877C176.196 74.9818 174.326 73.1125 172.02 73.1125C169.715 73.1125 167.845 74.9818 167.845 77.2877C167.845 79.5935 169.715 81.4628 172.02 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 81.4628C241.142 81.4628 243.011 79.5935 243.011 77.2877C243.011 74.9818 241.142 73.1125 238.836 73.1125C236.53 73.1125 234.661 74.9818 234.661 77.2877C234.661 79.5935 236.53 81.4628 238.836 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 81.4628C107.509 81.4628 109.378 79.5935 109.378 77.2877C109.378 74.9818 107.509 73.1125 105.203 73.1125C102.897 73.1125 101.028 74.9818 101.028 77.2877C101.028 79.5935 102.897 81.4628 105.203 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 81.4628C508.409 81.4628 510.278 79.5935 510.278 77.2877C510.278 74.9818 508.409 73.1125 506.103 73.1125C503.797 73.1125 501.928 74.9818 501.928 77.2877C501.928 79.5935 503.797 81.4628 506.103 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 81.4802C374.785 81.4802 376.66 79.6046 376.66 77.291C376.66 74.9773 374.785 73.1017 372.471 73.1017C370.158 73.1017 368.282 74.9773 368.282 77.291C368.282 79.6046 370.158 81.4802 372.471 81.4802Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 81.4628C441.591 81.4628 443.461 79.5935 443.461 77.2877C443.461 74.9818 441.591 73.1125 439.285 73.1125C436.98 73.1125 435.11 74.9818 435.11 77.2877C435.11 79.5935 436.98 81.4628 439.285 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 81.4628C7.29231 81.4628 9.16159 79.5935 9.16159 77.2877C9.16159 74.9818 7.29231 73.1125 4.98644 73.1125C2.68056 73.1125 0.811279 74.9818 0.811279 77.2877C0.811279 79.5935 2.68056 81.4628 4.98644 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 81.4628C541.811 81.4628 543.681 79.5935 543.681 77.2877C543.681 74.9818 541.811 73.1125 539.505 73.1125C537.2 73.1125 535.33 74.9818 535.33 77.2877C535.33 79.5935 537.2 81.4628 539.505 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 81.4628C274.543 81.4628 276.412 79.5935 276.412 77.2877C276.412 74.9818 274.543 73.1125 272.237 73.1125C269.931 73.1125 268.062 74.9818 268.062 77.2877C268.062 79.5935 269.931 81.4628 272.237 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 81.4628C140.913 81.4628 142.782 79.5935 142.782 77.2877C142.782 74.9818 140.913 73.1125 138.607 73.1125C136.301 73.1125 134.432 74.9818 134.432 77.2877C134.432 79.5935 136.301 81.4628 138.607 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 81.4628C207.725 81.4628 209.595 79.5935 209.595 77.2877C209.595 74.9818 207.725 73.1125 205.419 73.1125C203.114 73.1125 201.244 74.9818 201.244 77.2877C201.244 79.5935 203.114 81.4628 205.419 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 81.4628C74.0955 81.4628 75.9648 79.5935 75.9648 77.2877C75.9648 74.9818 74.0955 73.1125 71.7896 73.1125C69.4838 73.1125 67.6145 74.9818 67.6145 77.2877C67.6145 79.5935 69.4838 81.4628 71.7896 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 81.4628C474.996 81.4628 476.865 79.5935 476.865 77.2877C476.865 74.9818 474.996 73.1125 472.69 73.1125C470.384 73.1125 468.515 74.9818 468.515 77.2877C468.515 79.5935 470.384 81.4628 472.69 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 81.4628C341.36 81.4628 343.23 79.5935 343.23 77.2877C343.23 74.9818 341.36 73.1125 339.055 73.1125C336.749 73.1125 334.879 74.9818 334.879 77.2877C334.879 79.5935 336.749 81.4628 339.055 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 81.4628C408.178 81.4628 410.047 79.5935 410.047 77.2877C410.047 74.9818 408.178 73.1125 405.872 73.1125C403.566 73.1125 401.697 74.9818 401.697 77.2877C401.697 79.5935 403.566 81.4628 405.872 81.4628Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 118.016C40.6912 118.016 42.5605 116.146 42.5605 113.84C42.5605 111.534 40.6912 109.665 38.3854 109.665C36.0795 109.665 34.2102 111.534 34.2102 113.84C34.2102 116.146 36.0795 118.016 38.3854 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 118.016C575.226 118.016 577.096 116.146 577.096 113.84C577.096 111.534 575.226 109.665 572.921 109.665C570.615 109.665 568.745 111.534 568.745 113.84C568.745 116.146 570.615 118.016 572.921 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 118.027C307.967 118.027 309.843 116.152 309.843 113.838C309.843 111.525 307.967 109.649 305.654 109.649C303.34 109.649 301.464 111.525 301.464 113.838C301.464 116.152 303.34 118.027 305.654 118.027Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 118.016C174.326 118.016 176.196 116.146 176.196 113.84C176.196 111.534 174.326 109.665 172.02 109.665C169.715 109.665 167.845 111.534 167.845 113.84C167.845 116.146 169.715 118.016 172.02 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 118.016C241.142 118.016 243.011 116.146 243.011 113.84C243.011 111.534 241.142 109.665 238.836 109.665C236.53 109.665 234.661 111.534 234.661 113.84C234.661 116.146 236.53 118.016 238.836 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 118.016C107.509 118.016 109.378 116.146 109.378 113.84C109.378 111.534 107.509 109.665 105.203 109.665C102.897 109.665 101.028 111.534 101.028 113.84C101.028 116.146 102.897 118.016 105.203 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 118.016C508.409 118.016 510.278 116.146 510.278 113.84C510.278 111.534 508.409 109.665 506.103 109.665C503.797 109.665 501.928 111.534 501.928 113.84C501.928 116.146 503.797 118.016 506.103 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 118.027C374.785 118.027 376.66 116.152 376.66 113.838C376.66 111.525 374.785 109.649 372.471 109.649C370.158 109.649 368.282 111.525 368.282 113.838C368.282 116.152 370.158 118.027 372.471 118.027Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 118.016C441.591 118.016 443.461 116.146 443.461 113.84C443.461 111.534 441.591 109.665 439.285 109.665C436.98 109.665 435.11 111.534 435.11 113.84C435.11 116.146 436.98 118.016 439.285 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 118.016C7.29231 118.016 9.16159 116.146 9.16159 113.84C9.16159 111.534 7.29231 109.665 4.98644 109.665C2.68056 109.665 0.811279 111.534 0.811279 113.84C0.811279 116.146 2.68056 118.016 4.98644 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 118.016C541.811 118.016 543.681 116.146 543.681 113.84C543.681 111.534 541.811 109.665 539.505 109.665C537.2 109.665 535.33 111.534 535.33 113.84C535.33 116.146 537.2 118.016 539.505 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 118.016C274.543 118.016 276.412 116.146 276.412 113.84C276.412 111.534 274.543 109.665 272.237 109.665C269.931 109.665 268.062 111.534 268.062 113.84C268.062 116.146 269.931 118.016 272.237 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 118.016C140.913 118.016 142.782 116.146 142.782 113.84C142.782 111.534 140.913 109.665 138.607 109.665C136.301 109.665 134.432 111.534 134.432 113.84C134.432 116.146 136.301 118.016 138.607 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 118.016C207.725 118.016 209.595 116.146 209.595 113.84C209.595 111.534 207.725 109.665 205.419 109.665C203.114 109.665 201.244 111.534 201.244 113.84C201.244 116.146 203.114 118.016 205.419 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 118.016C74.0955 118.016 75.9648 116.146 75.9648 113.84C75.9648 111.534 74.0955 109.665 71.7896 109.665C69.4838 109.665 67.6145 111.534 67.6145 113.84C67.6145 116.146 69.4838 118.016 71.7896 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 118.016C474.996 118.016 476.865 116.146 476.865 113.84C476.865 111.534 474.996 109.665 472.69 109.665C470.384 109.665 468.515 111.534 468.515 113.84C468.515 116.146 470.384 118.016 472.69 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 118.016C341.36 118.016 343.23 116.146 343.23 113.84C343.23 111.534 341.36 109.665 339.055 109.665C336.749 109.665 334.879 111.534 334.879 113.84C334.879 116.146 336.749 118.016 339.055 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 118.016C408.178 118.016 410.047 116.146 410.047 113.84C410.047 111.534 408.178 109.665 405.872 109.665C403.566 109.665 401.697 111.534 401.697 113.84C401.697 116.146 403.566 118.016 405.872 118.016Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 154.565C40.6912 154.565 42.5605 152.695 42.5605 150.389C42.5605 148.084 40.6912 146.214 38.3854 146.214C36.0795 146.214 34.2102 148.084 34.2102 150.389C34.2102 152.695 36.0795 154.565 38.3854 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 154.565C575.226 154.565 577.096 152.695 577.096 150.389C577.096 148.084 575.226 146.214 572.921 146.214C570.615 146.214 568.745 148.084 568.745 150.389C568.745 152.695 570.615 154.565 572.921 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 154.58C307.967 154.58 309.843 152.705 309.843 150.391C309.843 148.077 307.967 146.202 305.654 146.202C303.34 146.202 301.464 148.077 301.464 150.391C301.464 152.705 303.34 154.58 305.654 154.58Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 154.565C174.326 154.565 176.196 152.695 176.196 150.389C176.196 148.084 174.326 146.214 172.02 146.214C169.715 146.214 167.845 148.084 167.845 150.389C167.845 152.695 169.715 154.565 172.02 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 154.565C241.142 154.565 243.011 152.695 243.011 150.389C243.011 148.084 241.142 146.214 238.836 146.214C236.53 146.214 234.661 148.084 234.661 150.389C234.661 152.695 236.53 154.565 238.836 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 154.565C107.509 154.565 109.378 152.695 109.378 150.389C109.378 148.084 107.509 146.214 105.203 146.214C102.897 146.214 101.028 148.084 101.028 150.389C101.028 152.695 102.897 154.565 105.203 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 154.565C508.409 154.565 510.278 152.695 510.278 150.389C510.278 148.084 508.409 146.214 506.103 146.214C503.797 146.214 501.928 148.084 501.928 150.389C501.928 152.695 503.797 154.565 506.103 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 154.58C374.785 154.58 376.66 152.705 376.66 150.391C376.66 148.077 374.785 146.202 372.471 146.202C370.158 146.202 368.282 148.077 368.282 150.391C368.282 152.705 370.158 154.58 372.471 154.58Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 154.565C441.591 154.565 443.461 152.695 443.461 150.389C443.461 148.084 441.591 146.214 439.285 146.214C436.98 146.214 435.11 148.084 435.11 150.389C435.11 152.695 436.98 154.565 439.285 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 154.565C7.29231 154.565 9.16159 152.695 9.16159 150.389C9.16159 148.084 7.29231 146.214 4.98644 146.214C2.68056 146.214 0.811279 148.084 0.811279 150.389C0.811279 152.695 2.68056 154.565 4.98644 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 154.565C541.811 154.565 543.681 152.695 543.681 150.389C543.681 148.084 541.811 146.214 539.505 146.214C537.2 146.214 535.33 148.084 535.33 150.389C535.33 152.695 537.2 154.565 539.505 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 154.565C274.543 154.565 276.412 152.695 276.412 150.389C276.412 148.084 274.543 146.214 272.237 146.214C269.931 146.214 268.062 148.084 268.062 150.389C268.062 152.695 269.931 154.565 272.237 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 154.565C140.913 154.565 142.782 152.695 142.782 150.389C142.782 148.084 140.913 146.214 138.607 146.214C136.301 146.214 134.432 148.084 134.432 150.389C134.432 152.695 136.301 154.565 138.607 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 154.565C207.725 154.565 209.595 152.695 209.595 150.389C209.595 148.084 207.725 146.214 205.419 146.214C203.114 146.214 201.244 148.084 201.244 150.389C201.244 152.695 203.114 154.565 205.419 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 154.565C74.0955 154.565 75.9648 152.695 75.9648 150.389C75.9648 148.084 74.0955 146.214 71.7896 146.214C69.4838 146.214 67.6145 148.084 67.6145 150.389C67.6145 152.695 69.4838 154.565 71.7896 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 154.565C474.996 154.565 476.865 152.695 476.865 150.389C476.865 148.084 474.996 146.214 472.69 146.214C470.384 146.214 468.515 148.084 468.515 150.389C468.515 152.695 470.384 154.565 472.69 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 154.565C341.36 154.565 343.23 152.695 343.23 150.389C343.23 148.084 341.36 146.214 339.055 146.214C336.749 146.214 334.879 148.084 334.879 150.389C334.879 152.695 336.749 154.565 339.055 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 154.565C408.178 154.565 410.047 152.695 410.047 150.389C410.047 148.084 408.178 146.214 405.872 146.214C403.566 146.214 401.697 148.084 401.697 150.389C401.697 152.695 403.566 154.565 405.872 154.565Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 191.115C40.6912 191.115 42.5605 189.246 42.5605 186.94C42.5605 184.634 40.6912 182.765 38.3854 182.765C36.0795 182.765 34.2102 184.634 34.2102 186.94C34.2102 189.246 36.0795 191.115 38.3854 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 191.115C575.226 191.115 577.096 189.246 577.096 186.94C577.096 184.634 575.226 182.765 572.921 182.765C570.615 182.765 568.745 184.634 568.745 186.94C568.745 189.246 570.615 191.115 572.921 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 191.129C307.967 191.129 309.843 189.254 309.843 186.94C309.843 184.626 307.967 182.751 305.654 182.751C303.34 182.751 301.464 184.626 301.464 186.94C301.464 189.254 303.34 191.129 305.654 191.129Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 191.115C174.326 191.115 176.196 189.246 176.196 186.94C176.196 184.634 174.326 182.765 172.02 182.765C169.715 182.765 167.845 184.634 167.845 186.94C167.845 189.246 169.715 191.115 172.02 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 191.115C241.142 191.115 243.011 189.246 243.011 186.94C243.011 184.634 241.142 182.765 238.836 182.765C236.53 182.765 234.661 184.634 234.661 186.94C234.661 189.246 236.53 191.115 238.836 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 191.115C107.509 191.115 109.378 189.246 109.378 186.94C109.378 184.634 107.509 182.765 105.203 182.765C102.897 182.765 101.028 184.634 101.028 186.94C101.028 189.246 102.897 191.115 105.203 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 191.115C508.409 191.115 510.278 189.246 510.278 186.94C510.278 184.634 508.409 182.765 506.103 182.765C503.797 182.765 501.928 184.634 501.928 186.94C501.928 189.246 503.797 191.115 506.103 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 191.129C374.785 191.129 376.66 189.254 376.66 186.94C376.66 184.626 374.785 182.751 372.471 182.751C370.158 182.751 368.282 184.626 368.282 186.94C368.282 189.254 370.158 191.129 372.471 191.129Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 191.115C441.591 191.115 443.461 189.246 443.461 186.94C443.461 184.634 441.591 182.765 439.285 182.765C436.98 182.765 435.11 184.634 435.11 186.94C435.11 189.246 436.98 191.115 439.285 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 191.115C7.29231 191.115 9.16159 189.246 9.16159 186.94C9.16159 184.634 7.29231 182.765 4.98644 182.765C2.68056 182.765 0.811279 184.634 0.811279 186.94C0.811279 189.246 2.68056 191.115 4.98644 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 191.115C541.811 191.115 543.681 189.246 543.681 186.94C543.681 184.634 541.811 182.765 539.505 182.765C537.2 182.765 535.33 184.634 535.33 186.94C535.33 189.246 537.2 191.115 539.505 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 191.115C274.543 191.115 276.412 189.246 276.412 186.94C276.412 184.634 274.543 182.765 272.237 182.765C269.931 182.765 268.062 184.634 268.062 186.94C268.062 189.246 269.931 191.115 272.237 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 191.115C140.913 191.115 142.782 189.246 142.782 186.94C142.782 184.634 140.913 182.765 138.607 182.765C136.301 182.765 134.432 184.634 134.432 186.94C134.432 189.246 136.301 191.115 138.607 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 191.115C207.725 191.115 209.595 189.246 209.595 186.94C209.595 184.634 207.725 182.765 205.419 182.765C203.114 182.765 201.244 184.634 201.244 186.94C201.244 189.246 203.114 191.115 205.419 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 191.115C74.0955 191.115 75.9648 189.246 75.9648 186.94C75.9648 184.634 74.0955 182.765 71.7896 182.765C69.4838 182.765 67.6145 184.634 67.6145 186.94C67.6145 189.246 69.4838 191.115 71.7896 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 191.115C474.996 191.115 476.865 189.246 476.865 186.94C476.865 184.634 474.996 182.765 472.69 182.765C470.384 182.765 468.515 184.634 468.515 186.94C468.515 189.246 470.384 191.115 472.69 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 191.115C341.36 191.115 343.23 189.246 343.23 186.94C343.23 184.634 341.36 182.765 339.055 182.765C336.749 182.765 334.879 184.634 334.879 186.94C334.879 189.246 336.749 191.115 339.055 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 191.115C408.178 191.115 410.047 189.246 410.047 186.94C410.047 184.634 408.178 182.765 405.872 182.765C403.566 182.765 401.697 184.634 401.697 186.94C401.697 189.246 403.566 191.115 405.872 191.115Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 227.666C40.6912 227.666 42.5605 225.797 42.5605 223.491C42.5605 221.185 40.6912 219.316 38.3854 219.316C36.0795 219.316 34.2102 221.185 34.2102 223.491C34.2102 225.797 36.0795 227.666 38.3854 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 227.666C575.226 227.666 577.096 225.797 577.096 223.491C577.096 221.185 575.226 219.316 572.921 219.316C570.615 219.316 568.745 221.185 568.745 223.491C568.745 225.797 570.615 227.666 572.921 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 227.682C307.967 227.682 309.843 225.806 309.843 223.493C309.843 221.179 307.967 219.303 305.654 219.303C303.34 219.303 301.464 221.179 301.464 223.493C301.464 225.806 303.34 227.682 305.654 227.682Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 227.666C174.326 227.666 176.196 225.797 176.196 223.491C176.196 221.185 174.326 219.316 172.02 219.316C169.715 219.316 167.845 221.185 167.845 223.491C167.845 225.797 169.715 227.666 172.02 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 227.666C241.142 227.666 243.011 225.797 243.011 223.491C243.011 221.185 241.142 219.316 238.836 219.316C236.53 219.316 234.661 221.185 234.661 223.491C234.661 225.797 236.53 227.666 238.836 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 227.666C107.509 227.666 109.378 225.797 109.378 223.491C109.378 221.185 107.509 219.316 105.203 219.316C102.897 219.316 101.028 221.185 101.028 223.491C101.028 225.797 102.897 227.666 105.203 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 227.666C508.409 227.666 510.278 225.797 510.278 223.491C510.278 221.185 508.409 219.316 506.103 219.316C503.797 219.316 501.928 221.185 501.928 223.491C501.928 225.797 503.797 227.666 506.103 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 227.682C374.785 227.682 376.66 225.806 376.66 223.493C376.66 221.179 374.785 219.303 372.471 219.303C370.158 219.303 368.282 221.179 368.282 223.493C368.282 225.806 370.158 227.682 372.471 227.682Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 227.666C441.591 227.666 443.461 225.797 443.461 223.491C443.461 221.185 441.591 219.316 439.285 219.316C436.98 219.316 435.11 221.185 435.11 223.491C435.11 225.797 436.98 227.666 439.285 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 227.666C7.29231 227.666 9.16159 225.797 9.16159 223.491C9.16159 221.185 7.29231 219.316 4.98644 219.316C2.68056 219.316 0.811279 221.185 0.811279 223.491C0.811279 225.797 2.68056 227.666 4.98644 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 227.666C541.811 227.666 543.681 225.797 543.681 223.491C543.681 221.185 541.811 219.316 539.505 219.316C537.2 219.316 535.33 221.185 535.33 223.491C535.33 225.797 537.2 227.666 539.505 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 227.666C274.543 227.666 276.412 225.797 276.412 223.491C276.412 221.185 274.543 219.316 272.237 219.316C269.931 219.316 268.062 221.185 268.062 223.491C268.062 225.797 269.931 227.666 272.237 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 227.666C140.913 227.666 142.782 225.797 142.782 223.491C142.782 221.185 140.913 219.316 138.607 219.316C136.301 219.316 134.432 221.185 134.432 223.491C134.432 225.797 136.301 227.666 138.607 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 227.666C207.725 227.666 209.595 225.797 209.595 223.491C209.595 221.185 207.725 219.316 205.419 219.316C203.114 219.316 201.244 221.185 201.244 223.491C201.244 225.797 203.114 227.666 205.419 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 227.666C74.0955 227.666 75.9648 225.797 75.9648 223.491C75.9648 221.185 74.0955 219.316 71.7896 219.316C69.4838 219.316 67.6145 221.185 67.6145 223.491C67.6145 225.797 69.4838 227.666 71.7896 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 227.666C474.996 227.666 476.865 225.797 476.865 223.491C476.865 221.185 474.996 219.316 472.69 219.316C470.384 219.316 468.515 221.185 468.515 223.491C468.515 225.797 470.384 227.666 472.69 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 227.666C341.36 227.666 343.23 225.797 343.23 223.491C343.23 221.185 341.36 219.316 339.055 219.316C336.749 219.316 334.879 221.185 334.879 223.491C334.879 225.797 336.749 227.666 339.055 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 227.666C408.178 227.666 410.047 225.797 410.047 223.491C410.047 221.185 408.178 219.316 405.872 219.316C403.566 219.316 401.697 221.185 401.697 223.491C401.697 225.797 403.566 227.666 405.872 227.666Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 264.214C40.6912 264.214 42.5605 262.344 42.5605 260.038C42.5605 257.733 40.6912 255.863 38.3854 255.863C36.0795 255.863 34.2102 257.733 34.2102 260.038C34.2102 262.344 36.0795 264.214 38.3854 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 264.214C575.226 264.214 577.096 262.344 577.096 260.038C577.096 257.733 575.226 255.863 572.921 255.863C570.615 255.863 568.745 257.733 568.745 260.038C568.745 262.344 570.615 264.214 572.921 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 264.231C307.967 264.231 309.843 262.355 309.843 260.042C309.843 257.728 307.967 255.853 305.654 255.853C303.34 255.853 301.464 257.728 301.464 260.042C301.464 262.355 303.34 264.231 305.654 264.231Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 264.214C174.326 264.214 176.196 262.344 176.196 260.038C176.196 257.733 174.326 255.863 172.02 255.863C169.715 255.863 167.845 257.733 167.845 260.038C167.845 262.344 169.715 264.214 172.02 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 264.214C241.142 264.214 243.011 262.344 243.011 260.038C243.011 257.733 241.142 255.863 238.836 255.863C236.53 255.863 234.661 257.733 234.661 260.038C234.661 262.344 236.53 264.214 238.836 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 264.214C107.509 264.214 109.378 262.344 109.378 260.038C109.378 257.733 107.509 255.863 105.203 255.863C102.897 255.863 101.028 257.733 101.028 260.038C101.028 262.344 102.897 264.214 105.203 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 264.214C508.409 264.214 510.278 262.344 510.278 260.038C510.278 257.733 508.409 255.863 506.103 255.863C503.797 255.863 501.928 257.733 501.928 260.038C501.928 262.344 503.797 264.214 506.103 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 264.231C374.785 264.231 376.66 262.355 376.66 260.042C376.66 257.728 374.785 255.853 372.471 255.853C370.158 255.853 368.282 257.728 368.282 260.042C368.282 262.355 370.158 264.231 372.471 264.231Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 264.214C441.591 264.214 443.461 262.344 443.461 260.038C443.461 257.733 441.591 255.863 439.285 255.863C436.98 255.863 435.11 257.733 435.11 260.038C435.11 262.344 436.98 264.214 439.285 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 264.214C7.29231 264.214 9.16159 262.344 9.16159 260.038C9.16159 257.733 7.29231 255.863 4.98644 255.863C2.68056 255.863 0.811279 257.733 0.811279 260.038C0.811279 262.344 2.68056 264.214 4.98644 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 264.214C541.811 264.214 543.681 262.344 543.681 260.038C543.681 257.733 541.811 255.863 539.505 255.863C537.2 255.863 535.33 257.733 535.33 260.038C535.33 262.344 537.2 264.214 539.505 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 264.214C274.543 264.214 276.412 262.344 276.412 260.038C276.412 257.733 274.543 255.863 272.237 255.863C269.931 255.863 268.062 257.733 268.062 260.038C268.062 262.344 269.931 264.214 272.237 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 264.214C140.913 264.214 142.782 262.344 142.782 260.038C142.782 257.733 140.913 255.863 138.607 255.863C136.301 255.863 134.432 257.733 134.432 260.038C134.432 262.344 136.301 264.214 138.607 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 264.214C207.725 264.214 209.595 262.344 209.595 260.038C209.595 257.733 207.725 255.863 205.419 255.863C203.114 255.863 201.244 257.733 201.244 260.038C201.244 262.344 203.114 264.214 205.419 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 264.214C74.0955 264.214 75.9648 262.344 75.9648 260.038C75.9648 257.733 74.0955 255.863 71.7896 255.863C69.4838 255.863 67.6145 257.733 67.6145 260.038C67.6145 262.344 69.4838 264.214 71.7896 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 264.214C474.996 264.214 476.865 262.344 476.865 260.038C476.865 257.733 474.996 255.863 472.69 255.863C470.384 255.863 468.515 257.733 468.515 260.038C468.515 262.344 470.384 264.214 472.69 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 264.214C341.36 264.214 343.23 262.344 343.23 260.038C343.23 257.733 341.36 255.863 339.055 255.863C336.749 255.863 334.879 257.733 334.879 260.038C334.879 262.344 336.749 264.214 339.055 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 264.214C408.178 264.214 410.047 262.344 410.047 260.038C410.047 257.733 408.178 255.863 405.872 255.863C403.566 255.863 401.697 257.733 401.697 260.038C401.697 262.344 403.566 264.214 405.872 264.214Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 300.766C40.6912 300.766 42.5605 298.897 42.5605 296.591C42.5605 294.285 40.6912 292.416 38.3854 292.416C36.0795 292.416 34.2102 294.285 34.2102 296.591C34.2102 298.897 36.0795 300.766 38.3854 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 300.766C575.226 300.766 577.096 298.897 577.096 296.591C577.096 294.285 575.226 292.416 572.921 292.416C570.615 292.416 568.745 294.285 568.745 296.591C568.745 298.897 570.615 300.766 572.921 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 300.778C307.967 300.778 309.843 298.903 309.843 296.589C309.843 294.275 307.967 292.4 305.654 292.4C303.34 292.4 301.464 294.275 301.464 296.589C301.464 298.903 303.34 300.778 305.654 300.778Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 300.766C174.326 300.766 176.196 298.897 176.196 296.591C176.196 294.285 174.326 292.416 172.02 292.416C169.715 292.416 167.845 294.285 167.845 296.591C167.845 298.897 169.715 300.766 172.02 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 300.766C241.142 300.766 243.011 298.897 243.011 296.591C243.011 294.285 241.142 292.416 238.836 292.416C236.53 292.416 234.661 294.285 234.661 296.591C234.661 298.897 236.53 300.766 238.836 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 300.766C107.509 300.766 109.378 298.897 109.378 296.591C109.378 294.285 107.509 292.416 105.203 292.416C102.897 292.416 101.028 294.285 101.028 296.591C101.028 298.897 102.897 300.766 105.203 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 300.766C508.409 300.766 510.278 298.897 510.278 296.591C510.278 294.285 508.409 292.416 506.103 292.416C503.797 292.416 501.928 294.285 501.928 296.591C501.928 298.897 503.797 300.766 506.103 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 300.778C374.785 300.778 376.66 298.903 376.66 296.589C376.66 294.275 374.785 292.4 372.471 292.4C370.158 292.4 368.282 294.275 368.282 296.589C368.282 298.903 370.158 300.778 372.471 300.778Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 300.766C441.591 300.766 443.461 298.897 443.461 296.591C443.461 294.285 441.591 292.416 439.285 292.416C436.98 292.416 435.11 294.285 435.11 296.591C435.11 298.897 436.98 300.766 439.285 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 300.766C7.29231 300.766 9.16159 298.897 9.16159 296.591C9.16159 294.285 7.29231 292.416 4.98644 292.416C2.68056 292.416 0.811279 294.285 0.811279 296.591C0.811279 298.897 2.68056 300.766 4.98644 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 300.766C541.811 300.766 543.681 298.897 543.681 296.591C543.681 294.285 541.811 292.416 539.505 292.416C537.2 292.416 535.33 294.285 535.33 296.591C535.33 298.897 537.2 300.766 539.505 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 300.766C274.543 300.766 276.412 298.897 276.412 296.591C276.412 294.285 274.543 292.416 272.237 292.416C269.931 292.416 268.062 294.285 268.062 296.591C268.062 298.897 269.931 300.766 272.237 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 300.766C140.913 300.766 142.782 298.897 142.782 296.591C142.782 294.285 140.913 292.416 138.607 292.416C136.301 292.416 134.432 294.285 134.432 296.591C134.432 298.897 136.301 300.766 138.607 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 300.766C207.725 300.766 209.595 298.897 209.595 296.591C209.595 294.285 207.725 292.416 205.419 292.416C203.114 292.416 201.244 294.285 201.244 296.591C201.244 298.897 203.114 300.766 205.419 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 300.766C74.0955 300.766 75.9648 298.897 75.9648 296.591C75.9648 294.285 74.0955 292.416 71.7896 292.416C69.4838 292.416 67.6145 294.285 67.6145 296.591C67.6145 298.897 69.4838 300.766 71.7896 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 300.766C474.996 300.766 476.865 298.897 476.865 296.591C476.865 294.285 474.996 292.416 472.69 292.416C470.384 292.416 468.515 294.285 468.515 296.591C468.515 298.897 470.384 300.766 472.69 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 300.766C341.36 300.766 343.23 298.897 343.23 296.591C343.23 294.285 341.36 292.416 339.055 292.416C336.749 292.416 334.879 294.285 334.879 296.591C334.879 298.897 336.749 300.766 339.055 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 300.766C408.178 300.766 410.047 298.897 410.047 296.591C410.047 294.285 408.178 292.416 405.872 292.416C403.566 292.416 401.697 294.285 401.697 296.591C401.697 298.897 403.566 300.766 405.872 300.766Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 337.315C40.6912 337.315 42.5605 335.446 42.5605 333.14C42.5605 330.834 40.6912 328.965 38.3854 328.965C36.0795 328.965 34.2102 330.834 34.2102 333.14C34.2102 335.446 36.0795 337.315 38.3854 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 337.315C575.226 337.315 577.096 335.446 577.096 333.14C577.096 330.834 575.226 328.965 572.921 328.965C570.615 328.965 568.745 330.834 568.745 333.14C568.745 335.446 570.615 337.315 572.921 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 337.331C307.967 337.331 309.843 335.455 309.843 333.142C309.843 330.828 307.967 328.952 305.654 328.952C303.34 328.952 301.464 330.828 301.464 333.142C301.464 335.455 303.34 337.331 305.654 337.331Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 337.315C174.326 337.315 176.196 335.446 176.196 333.14C176.196 330.834 174.326 328.965 172.02 328.965C169.715 328.965 167.845 330.834 167.845 333.14C167.845 335.446 169.715 337.315 172.02 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 337.315C241.142 337.315 243.011 335.446 243.011 333.14C243.011 330.834 241.142 328.965 238.836 328.965C236.53 328.965 234.661 330.834 234.661 333.14C234.661 335.446 236.53 337.315 238.836 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 337.315C107.509 337.315 109.378 335.446 109.378 333.14C109.378 330.834 107.509 328.965 105.203 328.965C102.897 328.965 101.028 330.834 101.028 333.14C101.028 335.446 102.897 337.315 105.203 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 337.315C508.409 337.315 510.278 335.446 510.278 333.14C510.278 330.834 508.409 328.965 506.103 328.965C503.797 328.965 501.928 330.834 501.928 333.14C501.928 335.446 503.797 337.315 506.103 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 337.331C374.785 337.331 376.66 335.455 376.66 333.142C376.66 330.828 374.785 328.952 372.471 328.952C370.158 328.952 368.282 330.828 368.282 333.142C368.282 335.455 370.158 337.331 372.471 337.331Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 337.315C441.591 337.315 443.461 335.446 443.461 333.14C443.461 330.834 441.591 328.965 439.285 328.965C436.98 328.965 435.11 330.834 435.11 333.14C435.11 335.446 436.98 337.315 439.285 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 337.315C7.29231 337.315 9.16159 335.446 9.16159 333.14C9.16159 330.834 7.29231 328.965 4.98644 328.965C2.68056 328.965 0.811279 330.834 0.811279 333.14C0.811279 335.446 2.68056 337.315 4.98644 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 337.315C541.811 337.315 543.681 335.446 543.681 333.14C543.681 330.834 541.811 328.965 539.505 328.965C537.2 328.965 535.33 330.834 535.33 333.14C535.33 335.446 537.2 337.315 539.505 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 337.315C274.543 337.315 276.412 335.446 276.412 333.14C276.412 330.834 274.543 328.965 272.237 328.965C269.931 328.965 268.062 330.834 268.062 333.14C268.062 335.446 269.931 337.315 272.237 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 337.315C140.913 337.315 142.782 335.446 142.782 333.14C142.782 330.834 140.913 328.965 138.607 328.965C136.301 328.965 134.432 330.834 134.432 333.14C134.432 335.446 136.301 337.315 138.607 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 337.315C207.725 337.315 209.595 335.446 209.595 333.14C209.595 330.834 207.725 328.965 205.419 328.965C203.114 328.965 201.244 330.834 201.244 333.14C201.244 335.446 203.114 337.315 205.419 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 337.315C74.0955 337.315 75.9648 335.446 75.9648 333.14C75.9648 330.834 74.0955 328.965 71.7896 328.965C69.4838 328.965 67.6145 330.834 67.6145 333.14C67.6145 335.446 69.4838 337.315 71.7896 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 337.315C474.996 337.315 476.865 335.446 476.865 333.14C476.865 330.834 474.996 328.965 472.69 328.965C470.384 328.965 468.515 330.834 468.515 333.14C468.515 335.446 470.384 337.315 472.69 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 337.315C341.36 337.315 343.23 335.446 343.23 333.14C343.23 330.834 341.36 328.965 339.055 328.965C336.749 328.965 334.879 330.834 334.879 333.14C334.879 335.446 336.749 337.315 339.055 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 337.315C408.178 337.315 410.047 335.446 410.047 333.14C410.047 330.834 408.178 328.965 405.872 328.965C403.566 328.965 401.697 330.834 401.697 333.14C401.697 335.446 403.566 337.315 405.872 337.315Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 373.866C40.6912 373.866 42.5605 371.997 42.5605 369.691C42.5605 367.385 40.6912 365.516 38.3854 365.516C36.0795 365.516 34.2102 367.385 34.2102 369.691C34.2102 371.997 36.0795 373.866 38.3854 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.921 373.866C575.226 373.866 577.096 371.997 577.096 369.691C577.096 367.385 575.226 365.516 572.921 365.516C570.615 365.516 568.745 367.385 568.745 369.691C568.745 371.997 570.615 373.866 572.921 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 373.88C307.967 373.88 309.843 372.004 309.843 369.691C309.843 367.377 307.967 365.502 305.654 365.502C303.34 365.502 301.464 367.377 301.464 369.691C301.464 372.004 303.34 373.88 305.654 373.88Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 373.866C174.326 373.866 176.196 371.997 176.196 369.691C176.196 367.385 174.326 365.516 172.02 365.516C169.715 365.516 167.845 367.385 167.845 369.691C167.845 371.997 169.715 373.866 172.02 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.836 373.866C241.142 373.866 243.011 371.997 243.011 369.691C243.011 367.385 241.142 365.516 238.836 365.516C236.53 365.516 234.661 367.385 234.661 369.691C234.661 371.997 236.53 373.866 238.836 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 373.866C107.509 373.866 109.378 371.997 109.378 369.691C109.378 367.385 107.509 365.516 105.203 365.516C102.897 365.516 101.028 367.385 101.028 369.691C101.028 371.997 102.897 373.866 105.203 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.103 373.866C508.409 373.866 510.278 371.997 510.278 369.691C510.278 367.385 508.409 365.516 506.103 365.516C503.797 365.516 501.928 367.385 501.928 369.691C501.928 371.997 503.797 373.866 506.103 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 373.88C374.785 373.88 376.66 372.004 376.66 369.691C376.66 367.377 374.785 365.502 372.471 365.502C370.158 365.502 368.282 367.377 368.282 369.691C368.282 372.004 370.158 373.88 372.471 373.88Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 373.866C441.591 373.866 443.461 371.997 443.461 369.691C443.461 367.385 441.591 365.516 439.285 365.516C436.98 365.516 435.11 367.385 435.11 369.691C435.11 371.997 436.98 373.866 439.285 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98644 373.866C7.29231 373.866 9.16159 371.997 9.16159 369.691C9.16159 367.385 7.29231 365.516 4.98644 365.516C2.68056 365.516 0.811279 367.385 0.811279 369.691C0.811279 371.997 2.68056 373.866 4.98644 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 373.866C541.811 373.866 543.681 371.997 543.681 369.691C543.681 367.385 541.811 365.516 539.505 365.516C537.2 365.516 535.33 367.385 535.33 369.691C535.33 371.997 537.2 373.866 539.505 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 373.866C274.543 373.866 276.412 371.997 276.412 369.691C276.412 367.385 274.543 365.516 272.237 365.516C269.931 365.516 268.062 367.385 268.062 369.691C268.062 371.997 269.931 373.866 272.237 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 373.866C140.913 373.866 142.782 371.997 142.782 369.691C142.782 367.385 140.913 365.516 138.607 365.516C136.301 365.516 134.432 367.385 134.432 369.691C134.432 371.997 136.301 373.866 138.607 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 373.866C207.725 373.866 209.595 371.997 209.595 369.691C209.595 367.385 207.725 365.516 205.419 365.516C203.114 365.516 201.244 367.385 201.244 369.691C201.244 371.997 203.114 373.866 205.419 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7896 373.866C74.0955 373.866 75.9648 371.997 75.9648 369.691C75.9648 367.385 74.0955 365.516 71.7896 365.516C69.4838 365.516 67.6145 367.385 67.6145 369.691C67.6145 371.997 69.4838 373.866 71.7896 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.69 373.866C474.996 373.866 476.865 371.997 476.865 369.691C476.865 367.385 474.996 365.516 472.69 365.516C470.384 365.516 468.515 367.385 468.515 369.691C468.515 371.997 470.384 373.866 472.69 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.055 373.866C341.36 373.866 343.23 371.997 343.23 369.691C343.23 367.385 341.36 365.516 339.055 365.516C336.749 365.516 334.879 367.385 334.879 369.691C334.879 371.997 336.749 373.866 339.055 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.872 373.866C408.178 373.866 410.047 371.997 410.047 369.691C410.047 367.385 408.178 365.516 405.872 365.516C403.566 365.516 401.697 367.385 401.697 369.691C401.697 371.997 403.566 373.866 405.872 373.866Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 410.415C40.6912 410.415 42.5605 408.546 42.5605 406.24C42.5605 403.934 40.6912 402.065 38.3854 402.065C36.0795 402.065 34.2102 403.934 34.2102 406.24C34.2102 408.546 36.0795 410.415 38.3854 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 410.433C575.232 410.433 577.108 408.557 577.108 406.243C577.108 403.93 575.232 402.054 572.918 402.054C570.605 402.054 568.729 403.93 568.729 406.243C568.729 408.557 570.605 410.433 572.918 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 410.433C307.967 410.433 309.843 408.557 309.843 406.243C309.843 403.93 307.967 402.054 305.654 402.054C303.34 402.054 301.464 403.93 301.464 406.243C301.464 408.557 303.34 410.433 305.654 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 410.415C174.326 410.415 176.196 408.546 176.196 406.24C176.196 403.934 174.326 402.065 172.02 402.065C169.715 402.065 167.845 403.934 167.845 406.24C167.845 408.546 169.715 410.415 172.02 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 410.433C241.151 410.433 243.027 408.557 243.027 406.243C243.027 403.93 241.151 402.054 238.838 402.054C236.524 402.054 234.649 403.93 234.649 406.243C234.649 408.557 236.524 410.433 238.838 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 410.415C107.509 410.415 109.378 408.546 109.378 406.24C109.378 403.934 107.509 402.065 105.203 402.065C102.897 402.065 101.028 403.934 101.028 406.24C101.028 408.546 102.897 410.415 105.203 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 410.433C508.418 410.433 510.294 408.557 510.294 406.243C510.294 403.93 508.418 402.054 506.104 402.054C503.791 402.054 501.915 403.93 501.915 406.243C501.915 408.557 503.791 410.433 506.104 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 410.433C374.785 410.433 376.66 408.557 376.66 406.243C376.66 403.93 374.785 402.054 372.471 402.054C370.158 402.054 368.282 403.93 368.282 406.243C368.282 408.557 370.158 410.433 372.471 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 410.433C441.599 410.433 443.474 408.557 443.474 406.243C443.474 403.93 441.599 402.054 439.285 402.054C436.971 402.054 435.096 403.93 435.096 406.243C435.096 408.557 436.971 410.433 439.285 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 410.433C7.29973 410.433 9.1753 408.557 9.1753 406.243C9.1753 403.93 7.29973 402.054 4.98609 402.054C2.67245 402.054 0.796875 403.93 0.796875 406.243C0.796875 408.557 2.67245 410.433 4.98609 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 410.433C541.819 410.433 543.694 408.557 543.694 406.243C543.694 403.93 541.819 402.054 539.505 402.054C537.192 402.054 535.316 403.93 535.316 406.243C535.316 408.557 537.192 410.433 539.505 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 410.433C274.55 410.433 276.426 408.557 276.426 406.243C276.426 403.93 274.55 402.054 272.237 402.054C269.923 402.054 268.047 403.93 268.047 406.243C268.047 408.557 269.923 410.433 272.237 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 410.415C140.913 410.415 142.782 408.546 142.782 406.24C142.782 403.934 140.913 402.065 138.607 402.065C136.301 402.065 134.432 403.934 134.432 406.24C134.432 408.546 136.301 410.415 138.607 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 410.415C207.725 410.415 209.595 408.546 209.595 406.24C209.595 403.934 207.725 402.065 205.419 402.065C203.114 402.065 201.244 403.934 201.244 406.24C201.244 408.546 203.114 410.415 205.419 410.415Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 410.433C74.1011 410.433 75.9767 408.557 75.9767 406.243C75.9767 403.93 74.1011 402.054 71.7875 402.054C69.4738 402.054 67.5983 403.93 67.5983 406.243C67.5983 408.557 69.4738 410.433 71.7875 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 410.433C475.001 410.433 476.877 408.557 476.877 406.243C476.877 403.93 475.001 402.054 472.688 402.054C470.374 402.054 468.498 403.93 468.498 406.243C468.498 408.557 470.374 410.433 472.688 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 410.433C341.37 410.433 343.245 408.557 343.245 406.243C343.245 403.93 341.37 402.054 339.056 402.054C336.742 402.054 334.867 403.93 334.867 406.243C334.867 408.557 336.742 410.433 339.056 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 410.433C408.184 410.433 410.059 408.557 410.059 406.243C410.059 403.93 408.184 402.054 405.87 402.054C403.556 402.054 401.681 403.93 401.681 406.243C401.681 408.557 403.556 410.433 405.87 410.433Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 446.968C40.6912 446.968 42.5605 445.099 42.5605 442.793C42.5605 440.487 40.6912 438.618 38.3854 438.618C36.0795 438.618 34.2102 440.487 34.2102 442.793C34.2102 445.099 36.0795 446.968 38.3854 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 446.982C575.232 446.982 577.108 445.106 577.108 442.793C577.108 440.479 575.232 438.603 572.918 438.603C570.605 438.603 568.729 440.479 568.729 442.793C568.729 445.106 570.605 446.982 572.918 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 446.982C307.967 446.982 309.843 445.106 309.843 442.793C309.843 440.479 307.967 438.603 305.654 438.603C303.34 438.603 301.464 440.479 301.464 442.793C301.464 445.106 303.34 446.982 305.654 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 446.968C174.326 446.968 176.196 445.099 176.196 442.793C176.196 440.487 174.326 438.618 172.02 438.618C169.715 438.618 167.845 440.487 167.845 442.793C167.845 445.099 169.715 446.968 172.02 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 446.982C241.151 446.982 243.027 445.106 243.027 442.793C243.027 440.479 241.151 438.603 238.838 438.603C236.524 438.603 234.649 440.479 234.649 442.793C234.649 445.106 236.524 446.982 238.838 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 446.968C107.509 446.968 109.378 445.099 109.378 442.793C109.378 440.487 107.509 438.618 105.203 438.618C102.897 438.618 101.028 440.487 101.028 442.793C101.028 445.099 102.897 446.968 105.203 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 446.982C508.418 446.982 510.294 445.106 510.294 442.793C510.294 440.479 508.418 438.603 506.104 438.603C503.791 438.603 501.915 440.479 501.915 442.793C501.915 445.106 503.791 446.982 506.104 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 446.982C374.785 446.982 376.66 445.106 376.66 442.793C376.66 440.479 374.785 438.603 372.471 438.603C370.158 438.603 368.282 440.479 368.282 442.793C368.282 445.106 370.158 446.982 372.471 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 446.982C441.599 446.982 443.474 445.106 443.474 442.793C443.474 440.479 441.599 438.603 439.285 438.603C436.971 438.603 435.096 440.479 435.096 442.793C435.096 445.106 436.971 446.982 439.285 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 446.982C7.29973 446.982 9.1753 445.106 9.1753 442.793C9.1753 440.479 7.29973 438.603 4.98609 438.603C2.67245 438.603 0.796875 440.479 0.796875 442.793C0.796875 445.106 2.67245 446.982 4.98609 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 446.982C541.819 446.982 543.694 445.106 543.694 442.793C543.694 440.479 541.819 438.603 539.505 438.603C537.192 438.603 535.316 440.479 535.316 442.793C535.316 445.106 537.192 446.982 539.505 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 446.982C274.55 446.982 276.426 445.106 276.426 442.793C276.426 440.479 274.55 438.603 272.237 438.603C269.923 438.603 268.047 440.479 268.047 442.793C268.047 445.106 269.923 446.982 272.237 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 446.968C140.913 446.968 142.782 445.099 142.782 442.793C142.782 440.487 140.913 438.618 138.607 438.618C136.301 438.618 134.432 440.487 134.432 442.793C134.432 445.099 136.301 446.968 138.607 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 446.968C207.725 446.968 209.595 445.099 209.595 442.793C209.595 440.487 207.725 438.618 205.419 438.618C203.114 438.618 201.244 440.487 201.244 442.793C201.244 445.099 203.114 446.968 205.419 446.968Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 446.982C74.1011 446.982 75.9767 445.106 75.9767 442.793C75.9767 440.479 74.1011 438.603 71.7875 438.603C69.4738 438.603 67.5983 440.479 67.5983 442.793C67.5983 445.106 69.4738 446.982 71.7875 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 446.982C475.001 446.982 476.877 445.106 476.877 442.793C476.877 440.479 475.001 438.603 472.688 438.603C470.374 438.603 468.498 440.479 468.498 442.793C468.498 445.106 470.374 446.982 472.688 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 446.982C341.37 446.982 343.245 445.106 343.245 442.793C343.245 440.479 341.37 438.603 339.056 438.603C336.742 438.603 334.867 440.479 334.867 442.793C334.867 445.106 336.742 446.982 339.056 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 446.982C408.184 446.982 410.059 445.106 410.059 442.793C410.059 440.479 408.184 438.603 405.87 438.603C403.556 438.603 401.681 440.479 401.681 442.793C401.681 445.106 403.556 446.982 405.87 446.982Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 483.515C40.6912 483.515 42.5605 481.646 42.5605 479.34C42.5605 477.034 40.6912 475.165 38.3854 475.165C36.0795 475.165 34.2102 477.034 34.2102 479.34C34.2102 481.646 36.0795 483.515 38.3854 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 483.529C575.232 483.529 577.108 481.653 577.108 479.34C577.108 477.026 575.232 475.151 572.918 475.151C570.605 475.151 568.729 477.026 568.729 479.34C568.729 481.653 570.605 483.529 572.918 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 483.529C307.967 483.529 309.843 481.653 309.843 479.34C309.843 477.026 307.967 475.151 305.654 475.151C303.34 475.151 301.464 477.026 301.464 479.34C301.464 481.653 303.34 483.529 305.654 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 483.515C174.326 483.515 176.196 481.646 176.196 479.34C176.196 477.034 174.326 475.165 172.02 475.165C169.715 475.165 167.845 477.034 167.845 479.34C167.845 481.646 169.715 483.515 172.02 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 483.529C241.151 483.529 243.027 481.653 243.027 479.34C243.027 477.026 241.151 475.151 238.838 475.151C236.524 475.151 234.649 477.026 234.649 479.34C234.649 481.653 236.524 483.529 238.838 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 483.515C107.509 483.515 109.378 481.646 109.378 479.34C109.378 477.034 107.509 475.165 105.203 475.165C102.897 475.165 101.028 477.034 101.028 479.34C101.028 481.646 102.897 483.515 105.203 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 483.529C508.418 483.529 510.294 481.653 510.294 479.34C510.294 477.026 508.418 475.151 506.104 475.151C503.791 475.151 501.915 477.026 501.915 479.34C501.915 481.653 503.791 483.529 506.104 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 483.529C374.785 483.529 376.66 481.653 376.66 479.34C376.66 477.026 374.785 475.151 372.471 475.151C370.158 475.151 368.282 477.026 368.282 479.34C368.282 481.653 370.158 483.529 372.471 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 483.529C441.599 483.529 443.474 481.653 443.474 479.34C443.474 477.026 441.599 475.151 439.285 475.151C436.971 475.151 435.096 477.026 435.096 479.34C435.096 481.653 436.971 483.529 439.285 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 483.529C7.29973 483.529 9.1753 481.653 9.1753 479.34C9.1753 477.026 7.29973 475.151 4.98609 475.151C2.67245 475.151 0.796875 477.026 0.796875 479.34C0.796875 481.653 2.67245 483.529 4.98609 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 483.529C541.819 483.529 543.694 481.653 543.694 479.34C543.694 477.026 541.819 475.151 539.505 475.151C537.192 475.151 535.316 477.026 535.316 479.34C535.316 481.653 537.192 483.529 539.505 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 483.529C274.55 483.529 276.426 481.653 276.426 479.34C276.426 477.026 274.55 475.151 272.237 475.151C269.923 475.151 268.047 477.026 268.047 479.34C268.047 481.653 269.923 483.529 272.237 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 483.515C140.913 483.515 142.782 481.646 142.782 479.34C142.782 477.034 140.913 475.165 138.607 475.165C136.301 475.165 134.432 477.034 134.432 479.34C134.432 481.646 136.301 483.515 138.607 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 483.515C207.725 483.515 209.595 481.646 209.595 479.34C209.595 477.034 207.725 475.165 205.419 475.165C203.114 475.165 201.244 477.034 201.244 479.34C201.244 481.646 203.114 483.515 205.419 483.515Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 483.529C74.1011 483.529 75.9767 481.653 75.9767 479.34C75.9767 477.026 74.1011 475.151 71.7875 475.151C69.4738 475.151 67.5983 477.026 67.5983 479.34C67.5983 481.653 69.4738 483.529 71.7875 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 483.529C475.001 483.529 476.877 481.653 476.877 479.34C476.877 477.026 475.001 475.151 472.688 475.151C470.374 475.151 468.498 477.026 468.498 479.34C468.498 481.653 470.374 483.529 472.688 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 483.529C341.37 483.529 343.245 481.653 343.245 479.34C343.245 477.026 341.37 475.151 339.056 475.151C336.742 475.151 334.867 477.026 334.867 479.34C334.867 481.653 336.742 483.529 339.056 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 483.529C408.184 483.529 410.059 481.653 410.059 479.34C410.059 477.026 408.184 475.151 405.87 475.151C403.556 475.151 401.681 477.026 401.681 479.34C401.681 481.653 403.556 483.529 405.87 483.529Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 520.066C40.6912 520.066 42.5605 518.197 42.5605 515.891C42.5605 513.585 40.6912 511.716 38.3854 511.716C36.0795 511.716 34.2102 513.585 34.2102 515.891C34.2102 518.197 36.0795 520.066 38.3854 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 520.082C575.232 520.082 577.108 518.206 577.108 515.892C577.108 513.579 575.232 511.703 572.918 511.703C570.605 511.703 568.729 513.579 568.729 515.892C568.729 518.206 570.605 520.082 572.918 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 520.082C307.967 520.082 309.843 518.206 309.843 515.892C309.843 513.579 307.967 511.703 305.654 511.703C303.34 511.703 301.464 513.579 301.464 515.892C301.464 518.206 303.34 520.082 305.654 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 520.066C174.326 520.066 176.196 518.197 176.196 515.891C176.196 513.585 174.326 511.716 172.02 511.716C169.715 511.716 167.845 513.585 167.845 515.891C167.845 518.197 169.715 520.066 172.02 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 520.082C241.151 520.082 243.027 518.206 243.027 515.892C243.027 513.579 241.151 511.703 238.838 511.703C236.524 511.703 234.649 513.579 234.649 515.892C234.649 518.206 236.524 520.082 238.838 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 520.066C107.509 520.066 109.378 518.197 109.378 515.891C109.378 513.585 107.509 511.716 105.203 511.716C102.897 511.716 101.028 513.585 101.028 515.891C101.028 518.197 102.897 520.066 105.203 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 520.082C508.418 520.082 510.294 518.206 510.294 515.892C510.294 513.579 508.418 511.703 506.104 511.703C503.791 511.703 501.915 513.579 501.915 515.892C501.915 518.206 503.791 520.082 506.104 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 520.082C374.785 520.082 376.66 518.206 376.66 515.892C376.66 513.579 374.785 511.703 372.471 511.703C370.158 511.703 368.282 513.579 368.282 515.892C368.282 518.206 370.158 520.082 372.471 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 520.082C441.599 520.082 443.474 518.206 443.474 515.892C443.474 513.579 441.599 511.703 439.285 511.703C436.971 511.703 435.096 513.579 435.096 515.892C435.096 518.206 436.971 520.082 439.285 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 520.082C7.29973 520.082 9.1753 518.206 9.1753 515.892C9.1753 513.579 7.29973 511.703 4.98609 511.703C2.67245 511.703 0.796875 513.579 0.796875 515.892C0.796875 518.206 2.67245 520.082 4.98609 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 520.082C541.819 520.082 543.694 518.206 543.694 515.892C543.694 513.579 541.819 511.703 539.505 511.703C537.192 511.703 535.316 513.579 535.316 515.892C535.316 518.206 537.192 520.082 539.505 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 520.082C274.55 520.082 276.426 518.206 276.426 515.892C276.426 513.579 274.55 511.703 272.237 511.703C269.923 511.703 268.047 513.579 268.047 515.892C268.047 518.206 269.923 520.082 272.237 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 520.066C140.913 520.066 142.782 518.197 142.782 515.891C142.782 513.585 140.913 511.716 138.607 511.716C136.301 511.716 134.432 513.585 134.432 515.891C134.432 518.197 136.301 520.066 138.607 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 520.066C207.725 520.066 209.595 518.197 209.595 515.891C209.595 513.585 207.725 511.716 205.419 511.716C203.114 511.716 201.244 513.585 201.244 515.891C201.244 518.197 203.114 520.066 205.419 520.066Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 520.082C74.1011 520.082 75.9767 518.206 75.9767 515.892C75.9767 513.579 74.1011 511.703 71.7875 511.703C69.4738 511.703 67.5983 513.579 67.5983 515.892C67.5983 518.206 69.4738 520.082 71.7875 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 520.082C475.001 520.082 476.877 518.206 476.877 515.892C476.877 513.579 475.001 511.703 472.688 511.703C470.374 511.703 468.498 513.579 468.498 515.892C468.498 518.206 470.374 520.082 472.688 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 520.082C341.37 520.082 343.245 518.206 343.245 515.892C343.245 513.579 341.37 511.703 339.056 511.703C336.742 511.703 334.867 513.579 334.867 515.892C334.867 518.206 336.742 520.082 339.056 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 520.082C408.184 520.082 410.059 518.206 410.059 515.892C410.059 513.579 408.184 511.703 405.87 511.703C403.556 511.703 401.681 513.579 401.681 515.892C401.681 518.206 403.556 520.082 405.87 520.082Z"
              fill={svgbgcolor}
            />
            <path
              d="M38.3854 556.617C40.6912 556.617 42.5605 554.748 42.5605 552.442C42.5605 550.136 40.6912 548.267 38.3854 548.267C36.0795 548.267 34.2102 550.136 34.2102 552.442C34.2102 554.748 36.0795 556.617 38.3854 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M572.918 556.631C575.232 556.631 577.108 554.755 577.108 552.442C577.108 550.128 575.232 548.252 572.918 548.252C570.605 548.252 568.729 550.128 568.729 552.442C568.729 554.755 570.605 556.631 572.918 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M305.654 556.631C307.967 556.631 309.843 554.755 309.843 552.442C309.843 550.128 307.967 548.252 305.654 548.252C303.34 548.252 301.464 550.128 301.464 552.442C301.464 554.755 303.34 556.631 305.654 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M172.02 556.617C174.326 556.617 176.196 554.748 176.196 552.442C176.196 550.136 174.326 548.267 172.02 548.267C169.715 548.267 167.845 550.136 167.845 552.442C167.845 554.748 169.715 556.617 172.02 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M238.838 556.631C241.151 556.631 243.027 554.755 243.027 552.442C243.027 550.128 241.151 548.252 238.838 548.252C236.524 548.252 234.649 550.128 234.649 552.442C234.649 554.755 236.524 556.631 238.838 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M105.203 556.617C107.509 556.617 109.378 554.748 109.378 552.442C109.378 550.136 107.509 548.267 105.203 548.267C102.897 548.267 101.028 550.136 101.028 552.442C101.028 554.748 102.897 556.617 105.203 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M506.104 556.631C508.418 556.631 510.294 554.755 510.294 552.442C510.294 550.128 508.418 548.252 506.104 548.252C503.791 548.252 501.915 550.128 501.915 552.442C501.915 554.755 503.791 556.631 506.104 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M372.471 556.631C374.785 556.631 376.66 554.755 376.66 552.442C376.66 550.128 374.785 548.252 372.471 548.252C370.158 548.252 368.282 550.128 368.282 552.442C368.282 554.755 370.158 556.631 372.471 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M439.285 556.631C441.599 556.631 443.474 554.755 443.474 552.442C443.474 550.128 441.599 548.252 439.285 548.252C436.971 548.252 435.096 550.128 435.096 552.442C435.096 554.755 436.971 556.631 439.285 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M4.98609 556.631C7.29973 556.631 9.1753 554.755 9.1753 552.442C9.1753 550.128 7.29973 548.252 4.98609 548.252C2.67245 548.252 0.796875 550.128 0.796875 552.442C0.796875 554.755 2.67245 556.631 4.98609 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M539.505 556.631C541.819 556.631 543.694 554.755 543.694 552.442C543.694 550.128 541.819 548.252 539.505 548.252C537.192 548.252 535.316 550.128 535.316 552.442C535.316 554.755 537.192 556.631 539.505 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M272.237 556.631C274.55 556.631 276.426 554.755 276.426 552.442C276.426 550.128 274.55 548.252 272.237 548.252C269.923 548.252 268.047 550.128 268.047 552.442C268.047 554.755 269.923 556.631 272.237 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M138.607 556.617C140.913 556.617 142.782 554.748 142.782 552.442C142.782 550.136 140.913 548.267 138.607 548.267C136.301 548.267 134.432 550.136 134.432 552.442C134.432 554.748 136.301 556.617 138.607 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M205.419 556.617C207.725 556.617 209.595 554.748 209.595 552.442C209.595 550.136 207.725 548.267 205.419 548.267C203.114 548.267 201.244 550.136 201.244 552.442C201.244 554.748 203.114 556.617 205.419 556.617Z"
              fill={svgbgcolor}
            />
            <path
              d="M71.7875 556.631C74.1011 556.631 75.9767 554.755 75.9767 552.442C75.9767 550.128 74.1011 548.252 71.7875 548.252C69.4738 548.252 67.5983 550.128 67.5983 552.442C67.5983 554.755 69.4738 556.631 71.7875 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M472.688 556.631C475.001 556.631 476.877 554.755 476.877 552.442C476.877 550.128 475.001 548.252 472.688 548.252C470.374 548.252 468.498 550.128 468.498 552.442C468.498 554.755 470.374 556.631 472.688 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M339.056 556.631C341.37 556.631 343.245 554.755 343.245 552.442C343.245 550.128 341.37 548.252 339.056 548.252C336.742 548.252 334.867 550.128 334.867 552.442C334.867 554.755 336.742 556.631 339.056 556.631Z"
              fill={svgbgcolor}
            />
            <path
              d="M405.87 556.631C408.184 556.631 410.059 554.755 410.059 552.442C410.059 550.128 408.184 548.252 405.87 548.252C403.556 548.252 401.681 550.128 401.681 552.442C401.681 554.755 403.556 556.631 405.87 556.631Z"
              fill={svgbgcolor}
            />
          </pattern>

          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-checkers)"
          ></rect>
        </svg>
      </div>
    </div>
  );
}

export default Welcome;
